import { EventEmitter } from 'events';
import * as lpstream from 'length-prefixed-stream';
export class NetUniqueId {
    constructor(publicKey, privateKey) {
        this.publicKey = publicKey;
        this.privateKey = privateKey;
        this.id = Buffer.from(this.publicKey).toString('hex');
    }
    toString() {
        return this.id + '';
    }
    /** Override to prevent serialization of keypair */
    toJSON() {
        return this.toString();
    }
    equals(other) {
        return this.id === other.id;
    }
}
class NetConnection extends EventEmitter {
    constructor(id, stream) {
        super();
        this.authed = false;
        this.close = () => {
            this.connected = false;
            this.onClose();
        };
        this.onConnect = () => {
            this.connected = true;
            this.emit('connect');
        };
        this.onError = (e) => {
            console.error(`Connection error [${this.id}]`, e);
            this.close();
        };
        // Must use bind to allow overwrite in subclasses
        this.receive = this.receive.bind(this);
        this.id = id;
        if (stream) {
            this.internalStream = stream;
            this.encode = lpstream.encode();
            this.decode = lpstream.decode();
            this.encode.pipe(this.internalStream);
            this.internalStream.pipe(this.decode);
            this.decode.on('data', this.receive);
        }
    }
    get shortId() {
        return this.id.toString().substring(0, 7);
    }
    send(data) {
        if (this.encode) {
            this.encode.write(data);
        }
        else {
            throw new Error('No implementation for NetConnection.send available');
        }
    }
    receive(data) {
        this.emit('data', data, this);
    }
    onClose() {
        if (this.internalStream) {
            if (this.encode) {
                this.encode.unpipe(this.internalStream);
            }
            if (this.decode) {
                this.internalStream.unpipe(this.decode);
                this.decode.removeListener('data', this.receive);
            }
            this.internalStream = undefined;
            this.encode = undefined;
            this.decode = undefined;
        }
        this.emit('close');
        this.removeAllListeners();
    }
    getIP() {
        return '';
    }
    getPort() {
        return '';
    }
    toString() {
        return `${this.id.toString()} (${this.getIP()}:${this.getPort()})`;
    }
    /** Invoked when the client is fully authenticated. */
    auth() {
        if (this.authed) {
            throw new Error(`Client already authed [${this}]`);
        }
        this.authed = true;
        this.emit('authed');
    }
    /** Whether the client is fully authenticated. Should only send messages if this is true. */
    isAuthed() {
        return this.authed;
    }
    /** Whether the connection is ready to receive data. */
    readyToReceive() {
        return !!this.connected;
    }
    /**
     * Returns a promise for when the connection is ready to receive data.
     *
     * Default implementation assumes the connection is always ready to receive data.
     */
    waitUntilReady() {
        return Promise.resolve();
    }
}
export default NetConnection;
