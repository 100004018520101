import path from 'path';
import { AvatarRegistry } from '../../services/avatar';
import { FileSystem } from 'services/filesystem';
import { setSetting } from 'actions/settings';
import { getLocalAvatar } from 'reducers/settings';
import { digestBuffer } from 'utils/blob';
import { requestFile } from './netfile';
import { forEachUser } from 'lobby/reducers/users.helpers';
import { resizeAndCompressImage } from 'utils/image/process-image';
import { localUserId } from 'network';
import { AccountService } from 'account/account';
import { Mode } from 'services/opfs-fs';
const AVATAR_FILEPATH = '/avatar.jpg';
const readAvatar = async (fs) => {
    const file = await fs.readFile(AVATAR_FILEPATH);
    const digest = await digestBuffer(file);
    const filepath = `/public/${digest}.jpg`;
    return { file, filepath };
};
/** Initialize client avatars, called once at startup */
export const initAvatar = () => {
    return async (dispatch, getState) => {
        const fs = FileSystem.getInstance();
        const avatarRegistry = AvatarRegistry.getInstance();
        avatarRegistry.register({
            type: "uid" /* UserID */,
            params: [localUserId()]
        });
        if (!FEATURE_CUSTOM_AVATAR)
            return;
        // register custom avatar type
        avatarRegistry.registerType("file" /* File */, async (filepath) => {
            if (!path.isAbsolute(filepath))
                return;
            filepath = path.normalize(filepath);
            if (!filepath.startsWith('/public/'))
                return;
            try {
                const url = await fs.readFileURL(filepath);
                return url;
            }
            catch {
                // file doesn't exist
            }
        });
        // register existing custom avatar
        try {
            const { filepath: publicFilepath } = await readAvatar(fs);
            avatarRegistry.register({
                type: "file" /* File */,
                params: [publicFilepath],
                pii: true
            });
        }
        catch {
            // no custom avatar
        }
        // validate selected custom avatar
        dispatch(validateCustomAvatar());
    };
};
// if (accountService.tier >= MetastreamUserTier.Supporter) {
export const validateCustomAvatar = () => {
    return async (dispatch, getState, { avatarRegistry }) => {
        const accountService = AccountService.get();
        const avatar = getLocalAvatar(getState());
        if (!avatarRegistry.isValid(avatar))
            return;
        const { type } = avatarRegistry.parseURI(avatar);
        if (type !== "file" /* File */)
            return;
        if (accountService.tier >= 2 /* Supporter */) {
            const url = await avatarRegistry.resolve(avatar);
            if (!url)
                dispatch(selectAvatar(undefined));
        }
        else {
            // remove selected custom avatar if not permitted
            dispatch(selectAvatar(undefined));
        }
    };
};
export const selectAvatar = (avatar) => {
    return dispatch => {
        dispatch(setSetting('avatar', avatar ? avatar.uri : undefined));
        if (avatar) {
            gtag('event', 'select_avatar', {
                event_category: 'settings',
                event_label: avatar.pii ? avatar.type : avatar.uri
            });
        }
    };
};
export const createAvatar = (file) => {
    return async (dispatch, getState) => {
        const fs = FileSystem.getInstance();
        const avatarRegistry = AvatarRegistry.getInstance();
        // Delete old avatar if it was created by the user
        let prevAvatarFilepath;
        const prevAvatarUri = getLocalAvatar(getState());
        if (prevAvatarUri) {
            const prevAvatar = avatarRegistry.getByURI(prevAvatarUri);
            if (prevAvatar && prevAvatar.type === 'file') {
                prevAvatarFilepath = prevAvatar.params[0];
            }
        }
        // Create new avatar
        let publicFilepath;
        try {
            const arrayBuffer = await resizeAndCompressImage({
                data: file,
                width: 64,
                height: 64,
                quality: 80
            });
            const digest = await digestBuffer(arrayBuffer);
            publicFilepath = `/public/${digest}.jpg`;
            await fs.writeFile(AVATAR_FILEPATH, Buffer.from(arrayBuffer));
            try {
                await fs.unlink(publicFilepath);
            }
            catch { }
            await fs.symlink(AVATAR_FILEPATH, publicFilepath);
        }
        catch (e) {
            console.error(e);
            return;
        }
        const avatar = await avatarRegistry.register({
            type: "file" /* File */,
            params: [publicFilepath],
            pii: true
        });
        if (prevAvatarFilepath) {
            avatarRegistry.deleteByURI(prevAvatarUri);
            await fs.unlink(prevAvatarFilepath);
        }
        dispatch(selectAvatar(avatar));
    };
};
export const removeAvatar = (avatar) => {
    return async (dispatch, getState, { avatarRegistry }) => {
        if (avatar.type !== "file" /* File */)
            return;
        avatarRegistry.deleteByURI(avatar.uri);
        const currentAvatarUri = getLocalAvatar(getState());
        if (currentAvatarUri === avatar.uri) {
            const defaultAvatar = avatarRegistry
                .getAll()
                .find((avatar) => avatar.type === "uid" /* UserID */);
            if (defaultAvatar)
                dispatch(selectAvatar(defaultAvatar));
        }
        try {
            const { filepath } = await readAvatar(FileSystem.getInstance());
            await FileSystem.getInstance().unlink(filepath); // remove symlink
            await FileSystem.getInstance().unlink(AVATAR_FILEPATH);
        }
        catch (e) {
            console.error(e);
        }
    };
};
export const verifyUserAvatars = (context) => {
    return (dispatch, getState) => {
        forEachUser(getState(), user => {
            dispatch(verifyUserAvatar(user, context));
        });
    };
};
export const verifyUserAvatar = (user, context) => {
    return async (dispatch) => {
        if (!FEATURE_CUSTOM_AVATAR)
            return;
        const fs = FileSystem.getInstance();
        const avatarRegistry = AvatarRegistry.getInstance();
        const { avatar } = user;
        if (!avatar)
            return;
        const { type, params } = avatarRegistry.parseURI(avatar);
        if (type !== "file" /* File */)
            return;
        const filepath = params[0];
        try {
            await fs.access(filepath, Mode.F_OK);
            return; // file exists and we don't need to request it
        }
        catch { }
        await dispatch(requestFile(context, filepath, user.id));
        // HACK: update avatars
        avatarRegistry.emit('change');
    };
};
