const FS_VERSION = 1;
export var Mode;
(function (Mode) {
    Mode[Mode["F_OK"] = 0] = "F_OK";
    Mode[Mode["R_OK"] = 4] = "R_OK";
    Mode[Mode["W_OK"] = 2] = "W_OK"; // Check if writable (always true in OPFS)
})(Mode || (Mode = {}));
const DEFAULT_METADATA = {
    v: FS_VERSION,
    atime: 0,
    mtime: 0,
    ctime: 0,
    size: 0
};
function createError(message, code) {
    const error = new Error(message);
    error.code = code;
    return error;
}
class OPFSFileSystem {
    async getDirectoryHandle(path, create = false) {
        const root = await navigator.storage.getDirectory();
        if (!path || path === '/')
            return root;
        const parts = path.split('/').filter(Boolean);
        let currentDir = root;
        for (const part of parts) {
            currentDir = await currentDir.getDirectoryHandle(part, { create });
        }
        return currentDir;
    }
    async resolvePath(path, create = false) {
        const parts = path.split('/').filter(Boolean);
        const name = parts.pop();
        if (!name)
            throw new Error('Invalid path: ' + path);
        const dir = await this.getDirectoryHandle(parts.join('/'), create);
        return { dir, name };
    }
    async writeFile(path, data) {
        const { dir, name } = await this.resolvePath(path, true);
        const fileHandle = await dir.getFileHandle(name, { create: true });
        const writable = await fileHandle.createWritable();
        await writable.write(data);
        await writable.close();
        await this.updateMetadata(path, { mtime: Date.now(), size: data.length });
    }
    async readFile(path) {
        path = await this.resolveSymlink(path);
        const { dir, name } = await this.resolvePath(path, false);
        const fileHandle = await dir.getFileHandle(name, { create: false });
        const file = await fileHandle.getFile();
        const buffer = await file.arrayBuffer();
        const content = new Uint8Array(buffer);
        await this.updateMetadata(path, { atime: Date.now() });
        return content;
    }
    async unlink(path) {
        try {
            const { dir, name } = await this.resolvePath(path, false);
            await dir.removeEntry(name);
            await this.deleteMetadata(path);
        }
        catch (error) {
            if (error instanceof Error && error.name === 'NotFoundError') {
                throw createError(`ENOENT: no such file or directory, unlink '${path}'`, 'ENOENT');
            }
            else {
                throw error;
            }
        }
    }
    async mkdir(path) {
        await this.getDirectoryHandle(path, true);
    }
    async rmdir(path) {
        const { dir, name } = await this.resolvePath(path, false);
        await dir.removeEntry(name, { recursive: true });
    }
    async readdir(path) {
        const dir = await this.getDirectoryHandle(path, false);
        const entries = [];
        for await (const [name] of dir.entries()) {
            entries.push(name);
        }
        return entries;
    }
    async stat(path) {
        path = await this.resolveSymlink(path);
        const { dir, name } = await this.resolvePath(path, false);
        try {
            await dir.getFileHandle(name);
            const metadata = await this.getMetadata(path);
            return { ...metadata, isFile: true, isDirectory: false };
        }
        catch {
            await dir.getDirectoryHandle(name);
            return { atime: 0, mtime: 0, ctime: 0, size: 0, isFile: false, isDirectory: true };
        }
    }
    async access(path, mode = Mode.F_OK) {
        try {
            const stats = await opfs.stat(path);
            if (mode & Mode.F_OK)
                return; // File exists, no error
            // Simulating permission checks (OPFS doesn't restrict access)
            if (mode & Mode.R_OK || mode & Mode.W_OK)
                return; // Always OK in OPFS
        }
        catch (error) {
            throw createError(`ENOENT: no such file or directory, access '${path}'`, 'ENOENT');
        }
    }
    async copyFile(src, dest) {
        const srcContent = await this.readFile(src);
        await this.writeFile(dest, srcContent);
        const metadata = await this.getMetadata(src);
        await this.updateMetadata(dest, metadata);
    }
    async symlink(targetPath, linkPath) {
        await this.writeFile(linkPath, new Uint8Array());
        await this.updateMetadata(linkPath, { symlink: targetPath });
    }
    async resolveSymlink(path) {
        try {
            const metadata = await this.getMetadata(path);
            return metadata && metadata.symlink ? metadata.symlink : path;
        }
        catch {
            return path;
        }
    }
    async utimes(path, atime, mtime) {
        path = await this.resolveSymlink(path);
        await this.updateMetadata(path, { atime, mtime });
    }
    async getMetadataDirectoryHandle(path, create = false) {
        const root = await navigator.storage.getDirectory();
        let currentDir = await root.getDirectoryHandle('.meta', { create });
        const parts = path.split('/').filter(Boolean);
        for (const part of parts.slice(0, -1)) {
            currentDir = await currentDir.getDirectoryHandle(part, { create });
        }
        return currentDir;
    }
    async getMetadataHandle(path, create = false) {
        const metadataDir = await this.getMetadataDirectoryHandle(path, create);
        if (!metadataDir)
            return null;
        const parts = path.split('/').filter(Boolean);
        const filename = parts[parts.length - 1];
        try {
            return await metadataDir.getFileHandle(filename, { create });
        }
        catch {
            return null;
        }
    }
    async updateMetadata(path, updates) {
        const metadataHandle = await this.getMetadataHandle(path, true);
        if (!metadataHandle)
            return;
        let metadata;
        try {
            const file = await metadataHandle.getFile();
            metadata = JSON.parse(await file.text());
        }
        catch {
            metadata = {
                ...DEFAULT_METADATA,
                atime: Date.now(),
                mtime: Date.now(),
                ctime: Date.now()
            };
        }
        // Ensure that updates only contain valid metadata fields
        const validUpdates = {};
        if (updates.mtime !== undefined)
            validUpdates.mtime = updates.mtime;
        if (updates.atime !== undefined)
            validUpdates.atime = updates.atime;
        if (updates.size !== undefined)
            validUpdates.size = updates.size;
        if (updates.symlink !== undefined)
            validUpdates.symlink = updates.symlink;
        Object.assign(metadata, validUpdates);
        const writable = await metadataHandle.createWritable();
        await writable.write(JSON.stringify(metadata));
        await writable.close();
    }
    async getMetadata(path) {
        const metadataHandle = await this.getMetadataHandle(path, false);
        if (!metadataHandle)
            return DEFAULT_METADATA;
        try {
            const file = await metadataHandle.getFile();
            return JSON.parse(await file.text());
        }
        catch {
            return DEFAULT_METADATA;
        }
    }
    async deleteMetadata(path) {
        const metadataDir = await this.getMetadataDirectoryHandle(path, false);
        if (!metadataDir)
            return;
        const parts = path.split('/').filter(Boolean);
        const filename = parts.pop();
        if (!filename)
            return;
        try {
            await metadataDir.removeEntry(filename);
            // Remove empty directories recursively
            while (parts.length > 0) {
                const parentPath = parts.join('/');
                const parentDir = await this.getMetadataDirectoryHandle(parentPath, false);
                const entries = [];
                for await (const _ of parentDir.entries()) {
                    entries.push(_);
                }
                if (entries.length === 0) {
                    const root = await navigator.storage.getDirectory();
                    await root.removeEntry(`.meta/${parentPath}`, { recursive: true });
                }
                parts.pop();
            }
        }
        catch {
            // Ignore errors if file doesn't exist
        }
    }
}
// Export a singleton instance
export const opfs = new OPFSFileSystem();
