import { once } from 'lodash-es';
import { getManifestVersion } from 'utils/extension';
const ytDomains = ['www.youtube.com', 'www.youtu.be', 'm.youtube.com', 'youtube.com'];
// const maxDomains = ['play.max.com']
export const getDisabledMediaSessionDomains = once(() => {
    const extensionManifestVersion = getManifestVersion();
    if (extensionManifestVersion && extensionManifestVersion >= 3) {
        return new Set();
    }
    else {
        // Black youtube screens are only fixed in MV3 version
        return new Set([...ytDomains]);
    }
});
