export const getIsInstalled = () => typeof document !== 'undefined' &&
    typeof document.documentElement.dataset.extensionInstalled !== 'undefined';
export const getExtensionVersion = () => getIsInstalled() ? document.documentElement.dataset.extensionVersion : undefined;
export const getManifestVersion = () => {
    if (!getIsInstalled())
        return;
    try {
        const manifestVersionString = document.documentElement.dataset.extensionManifestVersion;
        // MV2 doesn't set the manifest version
        if (!manifestVersionString)
            return 2;
        const manifestVersion = parseInt(manifestVersionString, 10);
        return isNaN(manifestVersion) ? undefined : manifestVersion;
    }
    catch { }
};
export const canExtensionBlockRequests = () => {
    const manifestVersion = getManifestVersion();
    return typeof manifestVersion === 'number' ? manifestVersion === 2 : false;
};
export const dispatchExtensionMessage = (type, payload, extra) => {
    if (!type.startsWith('metastream-')) {
        throw new Error('Extension messages must start with metastream-');
    }
    const message = { type, payload, ...extra };
    const chrome = window.chrome;
    if (typeof chrome === 'object' && typeof chrome.runtime === 'object') {
        const extensionId = document.documentElement.dataset.extensionId;
        if (extensionId) {
            chrome.runtime.sendMessage(extensionId, message);
            return;
        }
    }
    window.postMessage(message, location.origin);
};
