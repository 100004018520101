import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { assetUrl } from 'utils/appUrl';
import styles from './UserAvatar.css';
import { useAppContext } from 'appContext';
export const UserAvatar = ({ className, avatar, fallbackAvatar, badge, disabled, selected, onClick }) => {
    const mountedRef = useRef(true);
    const { avatarRegistry } = useAppContext();
    const [avatarSrc, setAvatarSrc] = useState();
    const [waitingForUpdate, setWaitingForUpdate] = useState(false);
    const requestAvatar = (src) => {
        let img = new Image();
        img.onload = () => mountedRef.current && setAvatarSrc(src);
        img.src = src;
    };
    const resolveAvatar = async (avatarUri) => {
        let src;
        try {
            src = await avatarRegistry.resolve(avatarUri);
        }
        catch { }
        if (src) {
            requestAvatar(src);
        }
        else {
            if (avatarSrc)
                setAvatarSrc(undefined);
            if (fallbackAvatar) {
                let fallbackSrc;
                try {
                    fallbackSrc = await avatarRegistry.resolve(fallbackAvatar);
                }
                catch { }
                if (fallbackSrc)
                    requestAvatar(fallbackSrc);
            }
            setWaitingForUpdate(true);
        }
    };
    useEffect(function avatarUpdate() {
        if (avatar) {
            resolveAvatar(avatar);
        }
    }, [avatar, fallbackAvatar, waitingForUpdate]);
    useEffect(function retryAvatar() {
        const onChange = () => setWaitingForUpdate(false);
        if (waitingForUpdate) {
            avatarRegistry.on('change', onChange);
        }
        return () => {
            if (waitingForUpdate) {
                avatarRegistry.off('change', onChange);
            }
        };
    }, [waitingForUpdate, setWaitingForUpdate]);
    useEffect(function componentDidMount() {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    });
    const avatarImg = (React.createElement("img", { className: styles.image, src: avatarSrc || assetUrl('avatars/default.svg'), alt: "" }));
    return (React.createElement("div", { className: cx(className, 'avatar', styles.container, {
            [styles.selected]: selected,
            [styles.disabled]: disabled
        }) },
        !disabled && onClick ? (React.createElement("button", { type: "button", onClick: onClick }, avatarImg)) : (avatarImg),
        badge));
};
export const AvatarBadge = ({ className, onClick, children }) => React.createElement(onClick ? 'button' : 'div', {
    className: cx(styles.badge, className),
    ...(onClick ? { type: 'button', onClick } : undefined)
}, children);
