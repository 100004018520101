let EMOJI_REGEX;
try {
    // Need to use constructor to avoid syntax parsing error in Firefox
    // https://mathiasbynens.be/notes/es-unicode-property-escapes
    EMOJI_REGEX = new RegExp('\\p{Emoji_Modifier_Base}\\p{Emoji_Modifier}?|\\p{Emoji_Presentation}|\\p{Emoji}\\uFE0F', 'gu');
}
catch {
    // Fallback for browsers not supporting Unicode Property Escapes.
    EMOJI_REGEX = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;
}
export const stripEmoji = (str) => str.replace(EMOJI_REGEX, '');
// https://stackoverflow.com/a/18650828/1490006
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
