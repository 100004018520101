import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { VideoPlayer } from 'components/lobby/VideoPlayer';
import { Chat } from 'components/chat';
import styles from './GameLobby.css';
import { getCurrentMedia, getPlaybackState } from 'lobby/reducers/mediaPlayer.helpers';
import { TitleBar } from 'components/TitleBar';
import { PlaybackControls } from 'components/media/PlaybackControls';
import { ActivityMonitor } from 'components/lobby/ActivityMonitor';
import { WebBrowser } from 'components/browser/WebBrowser';
import { registerMediaShortcuts, unregisterMediaShortcuts } from 'lobby/actions/shortcuts';
import { Modal } from 'components/lobby/Modal';
import * as Modals from 'components/lobby/modals';
import { UserList } from './lobby/UserList';
import { MediaList } from './lobby/MediaList';
import { setLobbyModal } from '../actions/ui';
import { getNumUsers } from '../lobby/reducers/users.helpers';
import { setPendingMedia } from 'lobby/actions/mediaPlayer';
import { sendMediaRequest } from 'lobby/actions/media-request';
import { Sidebar } from './sidebar';
class _GameLobby extends React.Component {
    constructor() {
        super(...arguments);
        this.player = null;
        this.chat = null;
        this.state = { inactive: false };
        this.showBrowser = () => {
            this.openModal("browser" /* Browser */);
        };
        this.showInfo = (media) => {
            this.openModal("media-info" /* MediaInfo */, { media });
        };
        this.openModal = (modal, modalProps) => {
            this.setState({ modal, modalProps });
            this.props.setLobbyModal(modal);
        };
        this.closeModal = () => {
            this.setState({ modal: undefined, modalProps: undefined });
            if (this.props.modal) {
                this.props.setLobbyModal();
            }
        };
    }
    get isPlaying() {
        return this.props.playback === 1 /* Playing */;
    }
    get isInteracting() {
        return this.player && this.player.state.interacting;
    }
    get isInactive() {
        return (this.state.inactive &&
            this.isPlaying &&
            !(this.player && this.player.state.interacting) &&
            !this.state.modal);
    }
    componentDidMount() {
        this.props.registerMediaShortcuts();
        this.checkPendingMedia();
    }
    componentWillUnmount() {
        this.props.unregisterMediaShortcuts();
    }
    componentDidUpdate(prevProps) {
        if (this.props.modal &&
            this.props.modal !== prevProps.modal &&
            this.props.modal !== this.state.modal) {
            this.openModal(this.props.modal);
        }
        this.checkPendingMedia();
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, {
                lobbyInactive: this.isInactive,
                modalVisible: !!this.state.modal
            }) },
            React.createElement(ActivityMonitor, { onChange: active => {
                    this.setState({ inactive: !active });
                    const { player } = this;
                    if (this.isPlaying && player && player.state.interacting) {
                        player.exitInteractMode();
                    }
                } }),
            this.renderControls(),
            this.props.isChatDocked && React.createElement(Sidebar, { className: styles.chatDocked }),
            this.isInactive && React.createElement("div", { className: styles.inactiveOverlay })));
    }
    renderControls() {
        const { currentMedia: media } = this.props;
        const controls = this.isInteracting ? null : (React.createElement(React.Fragment, null,
            this.renderPlaybackControls(),
            !this.props.isChatDocked && (React.createElement(React.Fragment, null,
                React.createElement(UserList, { className: styles.users, onInvite: () => this.openModal("session-settings" /* SessionSettings */) }),
                React.createElement(MediaList, { className: styles.queue, onOpenMediaBrowser: this.showBrowser, onShowInfo: this.showInfo }),
                React.createElement(Chat, { theRef: el => (this.chat = el), className: styles.chatFloat, disabled: !!this.state.modal, showHint: true, fade: true }))),
            this.state.modal && this.renderModal()));
        // Hide back button when modal is open to avoid confusing the back button
        // as the way to close the modal
        const showBackButton = !this.state.modal;
        return (React.createElement("section", { className: cx(styles.controls, {
                [styles.controlsDocked]: this.props.isChatDocked
            }) },
            React.createElement(VideoPlayer, { theRef: el => (this.player = el), className: styles.video, onInteractChange: () => this.forceUpdate() }),
            controls,
            this.isInteracting ? null : (React.createElement(TitleBar, { className: styles.titlebar, title: media && media.title, showBackButton: showBackButton, fullWidth: !this.props.isChatDocked, onBack: goBack => {
                    if (this.props.host && this.props.isMultiplayer) {
                        this.openModal("end-session" /* EndSession */, { onConfirm: goBack });
                    }
                    else {
                        goBack();
                    }
                } }))));
    }
    renderModal() {
        let modalChildren;
        let fillHeight;
        const modalProps = { isHost: this.props.host };
        switch (this.state.modal) {
            case "browser" /* Browser */: {
                return (React.createElement(WebBrowser, Object.assign({ className: styles.modal, onClose: this.closeModal }, this.state.modalProps)));
            }
            case "media-info" /* MediaInfo */: {
                const media = (this.state.modalProps && this.state.modalProps.media) || this.props.currentMedia;
                modalChildren = React.createElement(Modals.MediaInfo, { media: media, onClose: this.closeModal });
                break;
            }
            case "end-session" /* EndSession */: {
                modalChildren = React.createElement(Modals.EndSession, Object.assign({ onCancel: this.closeModal }, this.state.modalProps));
                break;
            }
            case "session-settings" /* SessionSettings */:
                Object.assign(modalProps, { initialSelected: 'session' });
            // fallthrough to LobbyModal.Settings
            case "settings" /* Settings */: {
                modalChildren = React.createElement(Modals.Settings, Object.assign({}, modalProps, this.state.modalProps));
                fillHeight = true;
                break;
            }
            default:
                console.warn(`Unknown lobby modal '${this.state.modal}'`);
        }
        if (modalChildren) {
            return (React.createElement(Modal, { className: styles.modal, onClose: this.closeModal, fill: fillHeight }, modalChildren));
        }
    }
    renderPlaybackControls() {
        return (React.createElement(PlaybackControls, { className: styles.playbackControls, reload: () => {
                if (this.player) {
                    this.player.reload();
                }
            }, showInfo: this.showInfo, showInteract: () => {
                if (this.player) {
                    this.player.enterInteractMode();
                }
            }, toggleChat: () => {
                if (this.chat) {
                    this.chat.toggle();
                }
            }, openSettings: () => {
                this.openModal("settings" /* Settings */);
            } }));
    }
    checkPendingMedia() {
        const { pendingMedia, isExtensionInstalled } = this.props;
        if (pendingMedia && isExtensionInstalled) {
            this.props.clearPendingMedia();
            this.props.sendMediaRequest({
                ...pendingMedia,
                source: pendingMedia.source || 'pending',
                immediate: this.props.host
            });
            this.closeModal();
        }
    }
}
export const GameLobby = connect((state) => {
    return {
        currentMedia: getCurrentMedia(state),
        playback: getPlaybackState(state),
        modal: state.ui.lobbyModal,
        isChatDocked: state.settings.chatLocation === 1 /* DockRight */,
        isMultiplayer: getNumUsers(state) > 1,
        pendingMedia: state.mediaPlayer.pendingMedia,
        isExtensionInstalled: state.ui.isExtensionInstalled
    };
}, (dispatch) => ({
    registerMediaShortcuts: () => dispatch(registerMediaShortcuts()),
    unregisterMediaShortcuts: () => dispatch(unregisterMediaShortcuts()),
    setLobbyModal: modal => dispatch(setLobbyModal(modal)),
    clearPendingMedia: () => dispatch(setPendingMedia()),
    sendMediaRequest: (opts) => {
        dispatch(sendMediaRequest(opts));
    }
}))(_GameLobby);
