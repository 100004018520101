export const blobToArrayBuffer = async (blob) => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.readAsArrayBuffer(blob);
        fr.onloadend = () => {
            const { result } = fr;
            if (result instanceof ArrayBuffer) {
                resolve(result);
            }
            else {
                reject();
            }
        };
        fr.onerror = () => reject();
    });
};
export const digestBuffer = async (arrayBuffer) => {
    const hashBuffer = await crypto.subtle.digest('SHA-1', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};
