import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './SafeBrowsePrompt.css';
import { HighlightButton } from '../../common/button';
import { getHost } from '../../../utils/url';
import { SafeBrowse } from '../../../services/safeBrowse';
import { DimLink } from '../../common/typography';
import { Trans } from 'react-i18next';
import { t } from 'locale';
class _SafeBrowsePrompt extends React.Component {
    render() {
        const { url, onChange } = this.props;
        const host = getHost(url);
        return (React.createElement("div", { className: cx(styles.container) },
            React.createElement("p", { className: styles.emoji }, "\uD83E\uDD14"),
            React.createElement("p", null,
                React.createElement(Trans, { i18nKey: "safeBrowseLoadContent", values: { host } },
                    "Load content from ",
                    React.createElement("strong", null, host),
                    "?")),
            React.createElement("p", { className: styles.fullUrl }, url),
            React.createElement("div", null,
                React.createElement(HighlightButton, { size: "medium", icon: "check", highlight: true, onClick: () => {
                        this.props.onPermitOnce();
                        gtag('event', 'safe_browse', {
                            event_category: 'session',
                            event_label: 'once'
                        });
                    } }, t('once')),
                React.createElement(HighlightButton, { size: "medium", icon: "check", onClick: () => {
                        SafeBrowse.getInstance().permitURL(url);
                        onChange();
                        gtag('event', 'safe_browse', {
                            event_category: 'session',
                            event_label: 'always'
                        });
                    } }, t('always'))),
            React.createElement("p", null,
                React.createElement(DimLink, { href: "#", className: styles.disableLink, onClick: event => {
                        event.preventDefault();
                        SafeBrowse.getInstance().disable();
                        onChange();
                        gtag('event', 'safe_browse', {
                            event_category: 'session',
                            event_label: 'disable'
                        });
                    } }, t('safeBrowseDisableForSession')))));
    }
}
export const SafeBrowsePrompt = connect()(_SafeBrowsePrompt);
