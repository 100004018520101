import { GA_HEARTBEAT_INTERVAL } from 'constants/analytics';
import { isPlaying } from '../lobby/reducers/mediaPlayer.helpers';
let heartbeatIntervalId = null;
export async function initAnalytics(store, history) {
    // Edge case: reload on lobby page
    window.gtag = () => { };
    await sleepUntilHydrated(store);
    setupAnalytics(store);
    addSettingListener(store, () => setupAnalytics(store));
    const pageview = () => {
        // Commenting this out as this doesn't seem needed
        // gtag('event', 'page_view', {
        //   page_location: window.location.href,
        //   page_path: getHistoryPath(history),
        //   page_title: document.title
        // })
    };
    pageview();
    history.listen(pageview);
}
function setupAnalytics(store) {
    stopSession();
    const state = store.getState();
    const { allowTracking } = state.settings;
    if (!allowTracking) {
        console.debug('Analytics tracking disabled');
        window.gtag = () => { };
        return;
    }
    const clientId = process.env.GA_CLIENT_ID;
    if (!clientId)
        return;
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${clientId}`;
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
        window.dataLayer.push(arguments);
        startSession(store);
    };
    gtag('js', new Date());
    gtag('config', clientId);
}
const startSession = (store) => {
    stopSession();
    heartbeatIntervalId = setInterval(pacemaker(store), GA_HEARTBEAT_INTERVAL);
};
const stopSession = () => {
    if (heartbeatIntervalId) {
        clearInterval(heartbeatIntervalId);
        heartbeatIntervalId = null;
    }
};
async function sleepUntilHydrated(store) {
    return new Promise(resolve => {
        const unsubscribe = store.subscribe(() => {
            const { _persist } = store.getState();
            if (_persist && _persist.rehydrated) {
                unsubscribe();
                resolve();
            }
        });
    });
}
function addSettingListener(store, cb) {
    let prevState = store.getState();
    store.subscribe(() => {
        const state = store.getState();
        if (state.settings.allowTracking !== prevState.settings.allowTracking) {
            cb();
        }
        prevState = state;
    });
}
function getHistoryPath(history) {
    let pathname = history.location.pathname;
    if (pathname.startsWith('/join/')) {
        pathname = '/join/:session'; // hide identifying info
    }
    return pathname;
}
/** Create heartbeat generator */
const pacemaker = (store) => () => {
    if (isPlaying(store.getState())) {
        gtag('event', 'heartbeat', {
            event_category: 'app',
            non_interaction: true
        });
    }
};
