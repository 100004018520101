import { rpc } from 'network/middleware/rpc';
import { FileSystem } from 'services/filesystem';
import { FileTransferManager } from 'network/file-transfer-manager';
import { digestBuffer, blobToArrayBuffer } from 'utils/blob';
import { formatBytes } from 'utils/string';
import { Deferred } from 'utils/async';
import { getMimeType } from 'utils/file';
import path from 'path';
const FILE_TRANSFER_TIMEOUT = 30e3;
const FILE_SHA1_REGEX = /([a-z0-9]{40})\.\w{3,4}/;
/** 10 MB hard limit until there's a good reason to change this. */
const FILE_SIZE_LIMIT = 1024 * 1024 * 10;
const isValidFilenameHash = (filepath, hash) => {
    const filename = path.basename(filepath);
    const filenameHashMatch = filename.match(FILE_SHA1_REGEX);
    if (!filenameHashMatch)
        return false;
    const filenameHash = filenameHashMatch[1];
    return filenameHash === hash;
};
/** Active server downloads */
const serverDownloads = new Map();
const startDownload = (path) => serverDownloads.set(path, new Deferred());
const endDownload = (path) => {
    const deferred = serverDownloads.get(path);
    if (!deferred)
        return;
    deferred.resolve();
    serverDownloads.delete(path);
};
const isDownloading = (path) => serverDownloads.has(path);
const waitForDownload = (path) => {
    const deferred = serverDownloads.get(path);
    if (!deferred)
        return Promise.resolve();
    return deferred.promise;
};
const requestFileHandler = ({ path: filepath, token }) => async (dispatch, getState, { client, host }) => {
    if (!FEATURE_CUSTOM_AVATAR)
        return { status: 403 /* Forbidden */ };
    if (typeof filepath !== 'string' || typeof token !== 'string')
        return { status: 400 /* BadRequest */ };
    // Remove any parent '..' folders
    filepath = path.normalize(filepath);
    // For now, we're only permissing files within the public directory
    if (!filepath.startsWith('/public/'))
        return { status: 403 /* Forbidden */ };
    // Wait if server is still processing download
    if (host && isDownloading(filepath)) {
        await waitForDownload(filepath);
    }
    const ftm = FileTransferManager.getInstance();
    const fs = FileSystem.getInstance();
    let data;
    try {
        data = Buffer.from(await fs.readFile(filepath));
    }
    catch {
        return { status: 404 /* NotFound */ };
    }
    const length = data.length;
    if (length > FILE_SIZE_LIMIT)
        return { status: 403 /* Forbidden */ };
    const hash = await digestBuffer(data);
    // netfiles must include hash in filename
    if (!isValidFilenameHash(filepath, hash))
        return { status: 500 /* ServerError */ };
    // setup transfer immediately before sending response
    ftm.send(client, data, token);
    return {
        status: 200 /* Ok */,
        hash,
        length
    };
};
const requestFileOpts = { timeout: FILE_TRANSFER_TIMEOUT };
const server_requestFile = rpc('sv_requestFile', "server" /* Server */, requestFileHandler, requestFileOpts);
const client_requestFile = rpc('cl_requestFile', "client" /* Client */, requestFileHandler, requestFileOpts);
export const requestFile = (context, filepath, clientId) => {
    return async (dispatch) => {
        console.debug(`requestFile[${clientId}]: ${filepath}`);
        const requestTime = Date.now();
        const ftm = FileTransferManager.getInstance();
        const fs = FileSystem.getInstance();
        let senderConn;
        let responsePromise;
        let token;
        if (context.host) {
            const conn = context.server.getClientById(clientId);
            if (!conn)
                throw new Error(`requestFile: Failed to find client '${clientId}'`);
            senderConn = conn;
            token = ftm.prepareTransfer(senderConn);
            responsePromise = dispatch(client_requestFile({ path: filepath, token })(clientId));
            startDownload(filepath);
        }
        else {
            senderConn = context.client;
            token = ftm.prepareTransfer(senderConn);
            responsePromise = dispatch(server_requestFile({ path: filepath, token }));
        }
        try {
            const resp = await responsePromise;
            if (resp.status !== 200 /* Ok */) {
                console.error(`requestFile: failed for ${filepath} with ${resp.status} response status`);
                return;
            }
            const buffer = await ftm.receive(senderConn, token, resp.length);
            if (!buffer)
                return;
            const elapsed = Math.round(Date.now() - requestTime);
            const mimeType = getMimeType(filepath);
            const file = new File([buffer], filepath, { type: mimeType });
            const arrayBuffer = await blobToArrayBuffer(file);
            const digest = await digestBuffer(arrayBuffer);
            if (resp.hash !== digest) {
                console.error(`requestFile: mismatch for ${filepath} (${resp.hash} != ${digest})`);
                return;
            }
            // netfiles must include hash in filename
            if (!isValidFilenameHash(filepath, digest)) {
                console.error(`requestFile: mismatch for ${filepath} (${digest})`);
                return;
            }
            await fs.writeFile(filepath, Buffer.from(arrayBuffer));
            console.debug(`requestFile: received ${filepath} (${formatBytes(resp.length)}) in ${elapsed}ms`);
        }
        finally {
            endDownload(filepath);
        }
    };
};
