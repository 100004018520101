import { extname, basename } from 'path';
export const getFileExt = (filepath) => extname(filepath).toLowerCase().split('.').pop();
export const getFileName = (filepath) => basename(filepath);
const MIME_TABLE_LOOKUP = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    avif: 'image/avif',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    webp: 'image/webp',
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    m4a: 'audio/m4a',
    mp4: 'video/mp4',
    avi: 'video/x-msvideo',
    mov: 'video/quicktime',
    flv: 'video/x-flv',
    mkv: 'video/x-matroska',
    txt: 'text/plain',
    html: 'text/html',
    css: 'text/css',
    js: 'application/javascript',
    json: 'application/json',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip: 'application/zip',
    rar: 'application/x-rar-compressed',
    tar: 'application/x-tar',
    gz: 'application/gzip',
    bz2: 'application/x-bzip2',
    '7z': 'application/x-7z-compressed',
    xml: 'application/xml',
    csv: 'text/csv',
    ttf: 'application/x-font-ttf',
    woff: 'application/x-font-woff',
    woff2: 'application/x-font-woff2',
    eot: 'application/vnd.ms-fontobject',
    otf: 'application/x-font-otf',
    svgz: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    bmp: 'image/bmp',
    ico: 'image/x-icon',
    webm: 'video/webm',
    ogv: 'video/ogg',
    m4v: 'video/x-m4v',
    flac: 'audio/flac',
    ape: 'audio/x-ape',
    m4r: 'audio/m4r',
    m4b: 'audio/m4b',
    aac: 'audio/aac',
    ac3: 'audio/ac3',
    dts: 'audio/dts',
    amr: 'audio/amr',
    awb: 'audio/amr-wb',
    midi: 'audio/midi',
    smf: 'audio/smf',
    mka: 'audio/x-matroska',
    mks: 'video/x-matroska',
    srt: 'application/x-subrip',
    ass: 'application/x-substation-alpha',
    smi: 'application/smil',
    smil: 'application/smil',
    log: 'text/plain',
    conf: 'text/plain',
    ini: 'text/plain',
    tsv: 'text/tab-separated-values',
    ics: 'text/calendar',
    vcf: 'text/x-vcard',
    vcard: 'text/x-vcard',
    eml: 'message/rfc822',
    msg: 'application/vnd.ms-outlook',
    pst: 'application/vnd.ms-outlook',
    ost: 'application/vnd.ms-outlook',
    mht: 'message/rfc822',
    mhtml: 'message/rfc822',
    nfo: 'text/x-nfo',
    torrent: 'application/x-bittorrent',
    iso: 'application/x-iso9660-image',
    img: 'application/x-iso9660-image',
    bin: 'application/octet-stream',
    deb: 'application/x-debian-package',
    rpm: 'application/x-redhat-package-manager',
    exe: 'application/x-msdownload',
    msi: 'application/x-msi',
    cab: 'application/vnd.ms-cab-compressed',
    zipx: 'application/zip',
    jar: 'application/java-archive',
    war: 'application/java-archive',
    ear: 'application/java-archive',
    apk: 'application/vnd.android.package-archive',
    ipa: 'application/x-apple-diskimage',
    appx: 'application/x-appx',
    appxbundle: 'application/x-appx-bundle',
    msix: 'application/x-msix',
    msixbundle: 'application/x-msix-bundle',
    xpi: 'application/x-xpinstall',
    crx: 'application/x-chrome-extension',
    wasm: 'application/wasm',
};
export const getMimeType = (filepath) => {
    const fileExt = getFileExt(filepath);
    const mimeType = (fileExt && MIME_TABLE_LOOKUP[fileExt]) || MIME_TABLE_LOOKUP.bin;
    return mimeType;
};
