import { NetActions } from 'network/actions';
import { isType, actionCreator } from 'utils/redux';
import { getReplicatedState, getReplicatedDelta } from './sync.util';
export const netApplyFullUpdate = actionCreator('@@net/APPLY_FULL_UPDATE');
export const netApplyUpdate = actionCreator('@@net/APPLY_UPDATE');
const NetActionTypes = {
    FULL_UPDATE: 'FULL_UPDATE',
    UPDATE: 'UPDATE'
};
const SYNC_HEADER = Buffer.from('SYNC');
export const netSyncMiddleware = () => {
    let COMMIT_NUMBER = 0;
    return store => {
        const { dispatch, getState } = store;
        let server, host, replicated;
        const init = (options) => {
            server = options.server || null;
            host = options.host;
            replicated = options.replicated;
            console.debug('[Net] Init netSync', options);
            if (!server)
                return;
            if (host) {
                server.on('connect', (conn) => {
                    conn.once('authed', () => {
                        const state = getReplicatedState(getState(), options.replicated);
                        const action = { type: NetActionTypes.FULL_UPDATE, v: COMMIT_NUMBER, state };
                        const jsonStr = JSON.stringify(action);
                        const buf = Buffer.from(SYNC_HEADER + jsonStr);
                        if (server)
                            server.sendTo(conn.id.toString(), buf);
                    });
                });
            }
            // Apply diffs on connected clients
            server.on('data', (conn, data) => {
                if (!data.slice(0, SYNC_HEADER.length).equals(SYNC_HEADER)) {
                    return;
                }
                const json = data.toString('utf-8', SYNC_HEADER.length);
                const action = JSON.parse(json);
                console.debug(`[Net] Received action #${action.type} from ${conn.id}`, action);
                switch (action.type) {
                    case NetActionTypes.FULL_UPDATE:
                        COMMIT_NUMBER = action.v;
                        const partialState = action.state;
                        dispatch(netApplyFullUpdate(partialState));
                        break;
                    case NetActionTypes.UPDATE:
                        const diffs = action.d;
                        dispatch(netApplyUpdate(diffs));
                        break;
                }
            });
        };
        const destroy = () => {
            server = null;
            COMMIT_NUMBER = 0;
        };
        /** Relay state changes from Server to Clients */
        const relay = (delta) => {
            console.debug('[Net] netSyncMiddleware delta', delta);
            const action = {
                type: NetActionTypes.UPDATE,
                v: COMMIT_NUMBER,
                d: delta
            };
            console.debug(`[Net] Sending update #${COMMIT_NUMBER}`, action);
            const jsonStr = JSON.stringify(action);
            const buf = Buffer.from(SYNC_HEADER + jsonStr);
            if (server)
                server.send(buf);
        };
        return next => action => {
            if (isType(action, NetActions.connect)) {
                init(action.payload);
                return next(action);
            }
            else if (isType(action, NetActions.disconnect)) {
                destroy();
                return next(action);
            }
            const prevState = getState();
            const result = next(action);
            if (!host || !server) {
                return result;
            }
            const state = getState();
            const delta = getReplicatedDelta(prevState, state, replicated);
            if (delta && delta.length > 0) {
                relay(delta);
                COMMIT_NUMBER++;
            }
            return result;
        };
    };
};
