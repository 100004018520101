/** Load image blob and return ImageData */
async function loadSquareImage(blob) {
    const img = new Image();
    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
    });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx)
        throw new Error('Unable to create new context for image');
    // Draw image into center of perfect square (cover)
    const { width, height } = img;
    const imageSize = width > height ? height : width;
    [canvas.width, canvas.height] = [imageSize, imageSize];
    if (width > height) {
        const sw = width - height;
        ctx.drawImage(img, sw / 2, 0, imageSize, imageSize, 0, 0, imageSize, imageSize);
    }
    else {
        const sh = height - width;
        ctx.drawImage(img, 0, sh / 2, imageSize, imageSize, 0, 0, imageSize, imageSize);
    }
    const imageData = ctx.getImageData(0, 0, imageSize, imageSize);
    URL.revokeObjectURL(img.src);
    return imageData;
}
/** Resize and compress image as JPEG */
export const resizeAndCompressImage = async (opts) => {
    const image = await loadSquareImage(opts.data);
    const arrayBuffer = image.data.buffer;
    return new Promise((resolve, reject) => {
        const worker = new Worker('/image-worker.js');
        worker.addEventListener('message', ({ data }) => {
            worker.terminate();
            if (data instanceof ArrayBuffer) {
                resolve(data);
            }
            else if (typeof data === 'object' && data.error) {
                reject(data.error);
            }
            else {
                reject();
            }
        });
        worker.addEventListener('error', event => {
            worker.terminate();
            reject(event.message);
        });
        worker.postMessage({
            image: {
                width: image.width,
                height: image.height,
                data: arrayBuffer
            },
            opts: {
                width: opts.width,
                height: opts.height,
                quality: opts.quality
            }
        }, [arrayBuffer]);
    });
};
