import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isHost, isAdmin } from '../../lobby/reducers/users.helpers';
import { getMaxUsers } from '../../lobby/reducers/session';
import { server_kickUser, server_toggleUserRole, answerUserInvite } from '../../lobby/actions/users';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '../common/button';
import { ListOverlay } from './ListOverlay';
import { UserItem, ConnectedUserItem } from './UserItem';
import { server_answerClient } from '../../lobby/actions/user-init';
import { localUserId } from '../../network';
import { assetUrl } from 'utils/appUrl';
import { withNamespaces } from 'react-i18next';
import { setSetting } from 'actions/settings';
class _UserList extends Component {
    constructor() {
        super(...arguments);
        this.state = { sortedUsers: [] };
        this.listOverlay = null;
        this.renderInvite = (invite) => {
            return (React.createElement(UserItem, { key: invite.id, name: invite.name, avatar: invite.avatar, avatarBadge: assetUrl(`icons/badge/${invite.type}.svg`), requestApproval: true, onApprovalResponse: (approved) => {
                    this.props.dispatch(answerUserInvite({
                        ...invite,
                        response: approved ? 'YES' : 'NO'
                    }));
                } }));
        };
        this.renderUser = (user) => {
            const requestApproval = user.pending && this.props.isAdmin;
            if (!requestApproval && this.props.collapsible && this.props.collapsed) {
                return;
            }
            return (React.createElement(ConnectedUserItem, { key: user.id, user: user, name: user.name, avatar: user.avatar, showMenu: this.props.isAdmin && user.id !== localUserId(), onClickMenu: e => this.listOverlay.onSelect(e, user), requestApproval: requestApproval, onApprovalResponse: requestApproval
                    ? (approved) => {
                        this.props.dispatch(server_answerClient(user.id, approved));
                    }
                    : undefined }));
        };
        this.renderMenuOptions = (user, close) => {
            const { t, dispatch } = this.props;
            let items = [
                {
                    label: t('toggleDJ'),
                    onClick() {
                        dispatch(server_toggleUserRole(user.id, 1 /* DJ */));
                    }
                },
                {
                    label: t('kick'),
                    onClick() {
                        dispatch(server_kickUser(user.id));
                    }
                }
            ];
            return items.map((item, idx) => (React.createElement(MenuItem, { key: idx, onClick: () => {
                    item.onClick();
                    close();
                }, dense: true }, item.label)));
        };
    }
    get numUsers() {
        return this.state.sortedUsers.length;
    }
    get userSlots() {
        const { numUsers } = this;
        const { maxUsers } = this.props;
        return `${numUsers}` + (maxUsers && isFinite(maxUsers) ? `/${maxUsers}` : '');
    }
    componentWillMount() {
        this.updateUsers(this.props.users);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.users !== nextProps.users) {
            this.updateUsers(nextProps.users);
        }
    }
    updateUsers(userState) {
        const users = Object.values(userState.map).filter(user => user ? !user.pending || this.props.isHost : false);
        users.sort((a, b) => {
            if (!a || !b)
                return 0;
            // pending users at the top
            if (a.pending && !b.pending)
                return -1;
            if (!a.pending && b.pending)
                return 1;
            // desc role
            if (a.role > b.role)
                return -1;
            if (a.role < b.role)
                return 1;
            // alphabetical
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
        this.setState({ sortedUsers: users });
    }
    render() {
        const { t } = this.props;
        return (React.createElement(ListOverlay, { ref: (e) => (this.listOverlay = e), id: "userlist", className: cx(this.props.className, {
                collapsed: this.props.collapsed
            }), title: t('users'), tagline: this.userSlots, action: this.renderActions(), renderMenuOptions: this.renderMenuOptions, onTitleClick: this.props.collapsible
                ? () => {
                    this.props.dispatch(setSetting('userListCollapsed', collapsed => !collapsed));
                }
                : undefined },
            this.props.users.invites.map(this.renderInvite),
            this.state.sortedUsers.map(this.renderUser)));
    }
    renderActions() {
        const { t } = this.props;
        const isSoloSession = this.numUsers < 2;
        const isOffline = this.props.sessionMode === 1 /* Offline */;
        const highlight = isSoloSession && !isOffline;
        return (React.createElement(IconButton, { icon: "link", iconSize: "small", highlight: highlight, onClick: this.props.onInvite }, t('invite')));
    }
}
export const UserList = withNamespaces()(connect((state) => {
    return {
        maxUsers: getMaxUsers(state),
        users: state.users,
        isAdmin: isAdmin(state),
        isHost: isHost(state),
        sessionMode: state.settings.sessionMode,
        collapsed: !!state.settings.userListCollapsed
    };
})(_UserList));
