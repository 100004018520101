import shortid from 'shortid';
const DATA_HEADER = Buffer.from('DATA');
let fileTransferManager;
/**
 * Handles sending and receiving of data.
 *
 * Partially inspired by Source Engine's spray file implementation.
 */
let FileTransferManager = /** @class */ (() => {
    class FileTransferManager {
        constructor() {
            this.transfers = new Map();
            this.processTransfers = () => {
                this.transfers.forEach((tinfo, conn) => {
                    if (!conn.connected) {
                        this.transfers.delete(conn);
                    }
                    else if (tinfo.sending) {
                        // wait to finish sending data
                    }
                    else if (tinfo.pending.length > 0) {
                        // get our next queued transfer
                        const head = tinfo.pending.shift();
                        tinfo.sending = { ...head };
                        this.sendData(conn, tinfo);
                    }
                    else if (tinfo.listening) {
                        // wait for data
                    }
                    else {
                        // nothing left to do
                        this.transfers.delete(conn);
                    }
                });
            };
            this.onReceiveData = (data, conn) => {
                let pointer = 0;
                if (!data.slice(0, DATA_HEADER.length).equals(DATA_HEADER)) {
                    return;
                }
                pointer += DATA_HEADER.length;
                const tokenLength = data.readInt32LE(pointer);
                pointer += 4;
                const tokenBuf = data.slice(pointer, pointer + tokenLength);
                pointer += tokenLength;
                const token = tokenBuf.toString('utf-8');
                let tinfo = this.transfers.get(conn);
                if (!tinfo) {
                    console.debug(`Received unexpected chunk for ${token} from ${conn.id}`);
                    return;
                }
                const transfer = tinfo.receiving[token];
                if (!transfer) {
                    console.debug(`Received data for unknown transfer ${token} from ${conn.id}`);
                    return;
                }
                // Depending on the order of packets received, it's possible for data to be received
                // prior to `receive()` being called. In this case, just accept the data until we
                // call `receive()`.
                if (transfer.status === 0 /* Pending */) {
                    const newTransferState = {
                        status: 1 /* Receiving */,
                        chunks: [],
                        bytesReceived: 0
                    };
                    Object.assign(transfer, newTransferState);
                }
                if (transfer.status !== 1 /* Receiving */)
                    return;
                const chunk = data.slice(pointer);
                if (chunk.length > this.chunkSize) {
                    const newTransferState = {
                        status: 2 /* Error */,
                        reason: `Received transfer chunk larger than expected size: ${chunk.length}`
                    };
                    Object.assign(transfer, newTransferState);
                    if (transfer.callback)
                        transfer.callback(true);
                    return;
                }
                transfer.chunks.push(chunk);
                transfer.bytesReceived += chunk.length;
                const finished = transfer.size ? transfer.bytesReceived >= transfer.size : false;
                if (!finished)
                    return;
                if (transfer.callback)
                    transfer.callback(null);
            };
        }
        static getInstance() {
            return fileTransferManager || (fileTransferManager = new FileTransferManager());
        }
        get chunkSize() {
            return FileTransferManager.CHUNK_SIZE;
        }
        /** Initialize state for upcoming expected data transfer. */
        prepareTransfer(conn) {
            const tinfo = this.setupTransferInfo(conn);
            const token = shortid(); // TODO: use GUID generator?
            tinfo.receiving[token] = { status: 0 /* Pending */ };
            this.startListening(conn);
            return token;
        }
        async sendData(conn, tinfo) {
            const { token, data } = tinfo.sending;
            const dataSize = data.byteLength;
            const numChunks = Math.ceil(dataSize / this.chunkSize);
            for (let i = 0; i < numChunks; i++) {
                if (!conn.connected) {
                    this.processTransfers(); // cleanup
                    return;
                }
                const start = i * this.chunkSize;
                const end = Math.min(start + this.chunkSize, dataSize);
                const chunk = data.slice(start, end);
                const header = Buffer.alloc(DATA_HEADER.length + 4 + token.length);
                header.write(DATA_HEADER.toString());
                header.writeUInt32LE(token.length, DATA_HEADER.length);
                header.write(token, DATA_HEADER.length + 4, 'utf-8');
                const msg = Buffer.concat([header, Buffer.from(chunk)]);
                conn.send(msg);
                if (!conn.readyToReceive()) {
                    await conn.waitUntilReady();
                }
            }
            // clear send state and process next transfer
            tinfo.sending = undefined;
            if (this.processTimeoutId)
                clearTimeout(this.processTimeoutId);
            this.processTimeoutId = setTimeout(this.processTransfers, 0);
        }
        setupTransferInfo(conn) {
            if (this.transfers.has(conn)) {
                return this.transfers.get(conn);
            }
            else {
                const info = {
                    pending: [],
                    listening: false,
                    receiving: {}
                };
                this.transfers.set(conn, info);
                return info;
            }
        }
        send(conn, data, token) {
            const tinfo = this.setupTransferInfo(conn);
            tinfo.pending.push({ token, data });
            // defer send one tick to allow sending token over network first
            if (this.processTimeoutId)
                clearTimeout(this.processTimeoutId);
            this.processTimeoutId = setTimeout(this.processTransfers, 0);
        }
        startListening(conn) {
            const tinfo = this.transfers.get(conn);
            if (!tinfo || tinfo.listening)
                return;
            conn.on('data', this.onReceiveData);
            tinfo.listening = true;
        }
        stopListening(conn) {
            const tinfo = this.transfers.get(conn);
            if (!tinfo || !tinfo.listening)
                return;
            conn.off('data', this.onReceiveData);
            tinfo.listening = false;
        }
        onTransferFinish(conn, tinfo, token) {
            delete tinfo.receiving[token];
            if (Object.keys(tinfo.receiving).length === 0) {
                this.stopListening(conn);
            }
        }
        async receive(conn, token, byteLength) {
            const tinfo = this.setupTransferInfo(conn);
            // Transfers must be allocated for ahead of time
            if (!tinfo.receiving.hasOwnProperty(token)) {
                throw new Error(`receive() called with an unexpected token '${token}'`);
            }
            let callback;
            const transferPromise = new Promise((resolve, reject) => {
                callback = (err) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    resolve();
                };
            });
            let transferState = tinfo.receiving[token];
            if (transferState && transferState.status !== 0 /* Pending */) {
                switch (transferState.status) {
                    case 2 /* Error */:
                        this.onTransferFinish(conn, tinfo, token);
                        throw new Error(transferState.reason || `Failed transfer ${token}`);
                    case 1 /* Receiving */: {
                        Object.assign(transferState, {
                            size: byteLength,
                            callback: callback
                        });
                        // check if transfer already completed
                        if (transferState.bytesReceived === transferState.size) {
                            callback(null);
                        }
                        break;
                    }
                }
            }
            else {
                transferState = {
                    status: 1 /* Receiving */,
                    size: byteLength,
                    chunks: [],
                    bytesReceived: 0,
                    callback: callback
                };
                tinfo.receiving[token] = transferState;
            }
            await transferPromise;
            this.onTransferFinish(conn, tinfo, token);
            const finished = transferState.bytesReceived === transferState.size;
            if (!finished) {
                throw new Error(`Received incomplete transfer for ${token}`);
            }
            const data = Buffer.concat(transferState.chunks);
            return data;
        }
    }
    FileTransferManager.CHUNK_SIZE = 1024;
    return FileTransferManager;
})();
export { FileTransferManager };
