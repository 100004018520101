if (process.env.NODE_ENV === 'development') {
    const app = Object.create(null);
    window.app = app;
}
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './containers/Root';
import * as cfgStore from './store/configureStore';
import 'styles/app.global.css';
import { PlatformService } from 'platform';
import { initAnalytics } from './telemetry';
import { initLocale } from 'locale';
import { setPendingMedia } from 'lobby/actions/mediaPlayer';
import { sendMediaRequest } from 'lobby/actions/media-request';
import { SEC2MS } from 'utils/math';
import { AccountService } from 'account/account';
import { sleep } from 'utils/async';
import { checkExtensionInstall } from 'actions/ui';
import { AvatarRegistry } from 'services/avatar';
import { FileSystem } from 'services/filesystem';
import { initAvatar } from 'lobby/actions/user-avatar';
import { AppContext } from 'appContext';
import { initAdsense } from 'adsense';
let store;
let history;
let persistor;
function renderComplete() {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
        navigator.serviceWorker.register('/service-worker.js');
    }
}
function onMessage(event) {
    const { data } = event;
    if (typeof data !== 'object' || typeof data.type !== 'string')
        return;
    switch (data.type) {
        case 'metastream-extension-request':
            if (typeof data.payload === 'object') {
                const { url, time, source } = data.payload;
                store.dispatch(setPendingMedia({ url, time: time ? time * SEC2MS : undefined, source }));
            }
            break;
    }
}
function extensionInstalled() {
    store.dispatch(checkExtensionInstall());
}
function getInitialState() {
    const url = new URL(location.href);
    if (url.searchParams.has('initialState')) {
        const state = url.searchParams.get('initialState');
        return state ? JSON.parse(state) : undefined;
    }
}
async function main() {
    history = cfgStore.history;
    const avatarRegistry = AvatarRegistry.getInstance();
    const storeCfg = cfgStore.configureStore({
        initialState: process.env.NODE_ENV === 'development' ? getInitialState() : undefined,
        persistCallback: () => {
            const state = store.getState();
            initLocale(state.settings.language);
        },
        extra: {
            avatarRegistry
        }
    });
    store = storeCfg.store;
    persistor = storeCfg.persistor;
    if (process.env.NODE_ENV === 'development') {
        // Assign store early for e2e tests
        Object.assign(window.app, { store });
    }
    // setup listeners
    window.addEventListener('message', onMessage, false);
    document.addEventListener('metastreamRemoteInstalled', extensionInstalled);
    // fix: sometimes the extension installs too late and the app misses its check
    // this can be removed after Metastream Remote v0.4.2 is released
    setTimeout(extensionInstalled, 3e3);
    try {
        await Promise.race([
            AccountService.get().checkLogin(),
            sleep(15e3) // skip on timeout
        ]);
    }
    catch (e) {
        console.error(e);
    }
    // Setup libsodium and cryptographic identity
    const platform = PlatformService.get();
    await platform.ready;
    await store.dispatch(initAvatar());
    initAnalytics(store, history);
    if (FEATURE_ADS)
        initAdsense();
    try {
        const fs = FileSystem.getInstance();
        // await fs.mkdir('/public')
        fs.setDirFileLimit('/public', 100);
    }
    catch (e) {
        if (e.code !== 'EEXIST') {
            console.error('Failed to initialize public directory', e);
        }
    }
    // DEBUG
    if (process.env.NODE_ENV === 'development') {
        ;
        window.app = {
            history,
            store,
            platform: PlatformService.get(),
            sendMediaRequest: function (opts) {
                return store.dispatch(sendMediaRequest(opts));
            },
            get fs() {
                return FileSystem.getInstance();
            }
        };
    }
    render(React.createElement(AppContainer, null,
        React.createElement(AppContext.Provider, { value: {
                avatarRegistry
            } },
            React.createElement(Root, { store: store, history: history, persistor: persistor }))), document.getElementById('root'), renderComplete);
}
main();
if (module.hot) {
    module.hot.accept('./containers/Root', () => {
        const NextRoot = require('./containers/Root').default;
        render(React.createElement(AppContainer, null,
            React.createElement(NextRoot, { store: store, history: history, persistor: persistor })), document.getElementById('root'));
    });
}
// if (process.env.NODE_ENV === 'development') {
//   window.addEventListener('beforeunload', () => {
//     debugger
//   })
// }
