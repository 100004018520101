import { dispatchExtensionMessage } from './extension';
const transformList = new Set(['user-agent', 'referer']);
const prefixHeaders = (headers) => {
    if (typeof headers === 'object') {
        headers = { ...headers };
        // Prefix reserved headers to be fixed in the extension background script
        Object.keys(headers).forEach(name => {
            if (transformList.has(name.toLowerCase())) {
                // TODO: refactor this to use declarativeNetRequest
                headers[`X-Metastream-${name}`] = headers[name];
            }
        });
    }
    return headers;
};
let fetchId = 0;
const mainFetch = (url, options = {}) => {
    return new Promise((resolve, reject) => {
        if (url.startsWith('//')) {
            url = `https:${url}`;
        }
        try {
            new URL(url);
        }
        catch (e) {
            reject(e);
            return;
        }
        options = {
            credentials: 'omit',
            ...options,
            headers: prefixHeaders(options.headers)
        };
        const requestId = fetchId++;
        const handler = (event) => {
            const { data } = event;
            if (typeof data !== 'object')
                return;
            if (data.type !== `metastream-fetch-response${requestId}`)
                return;
            window.removeEventListener('message', handler, false);
            const { err, resp } = data.payload;
            if (err) {
                reject(err);
                return;
            }
            resolve(resp);
        };
        window.addEventListener('message', handler, false);
        dispatchExtensionMessage('metastream-fetch', { requestId, url, options });
    });
};
export const fetchText = async (url, options) => {
    const resp = await mainFetch(url, options);
    return [resp.body, resp];
};
export const fetchResponse = async (url, options) => {
    const resp = await mainFetch(url, options);
    return resp;
};
export const isEmbeddingBlocked = (responseHeaders) => {
    const xFrameOptions = responseHeaders.find(header => header.name.toLowerCase() === 'x-frame-options');
    const frameOptions = responseHeaders.find(header => header.name.toLowerCase() === 'frame-options');
    const contentSecurityPolicy = responseHeaders.find(header => header.name.toLowerCase() === 'content-security-policy');
    if (xFrameOptions && ['deny', 'sameorigin'].includes(xFrameOptions.value.toLowerCase())) {
        return true;
    }
    if (frameOptions && frameOptions.value.toLowerCase() === 'deny') {
        return true;
    }
    if (contentSecurityPolicy && contentSecurityPolicy.value.includes('frame-ancestors')) {
        const frameAncestorsPolicy = contentSecurityPolicy.value.split(';').find(policy => policy.trim().startsWith('frame-ancestors'));
        if (frameAncestorsPolicy && !frameAncestorsPolicy.includes(window.location.origin)) {
            return true;
        }
    }
    return false;
};
