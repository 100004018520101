import React, { PureComponent } from 'react';
import cx from 'classnames';
import styles from './SettingsMenu.css';
import ProfileSettings from './sections/Profile';
import AdvancedSettings from './sections/Advanced';
import AppearanceSettings from './sections/Appearance';
import { t } from 'locale';
import SessionSettings from '../lobby/modals/SessionSettings';
import { connect } from 'react-redux';
import { setSetting } from '../../actions/settings';
class _SettingsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.initialSelected
        };
    }
    buildMenu() {
        const { inSession, isHost } = this.props;
        const settingsProps = {
            settings: this.props.settings,
            setSetting: this.props.setSetting
        };
        const tabs = [
            {
                label: t('profile'),
                value: 'profile',
                children: () => React.createElement(ProfileSettings, Object.assign({}, settingsProps))
            },
            inSession && {
                label: t('session'),
                value: 'session',
                children: () => React.createElement(SessionSettings, { className: styles.section })
            },
            {
                label: t('appearance'),
                value: 'appearance',
                children: () => React.createElement(AppearanceSettings, Object.assign({ onChange: this.props.invalidate }, settingsProps))
            },
            {
                label: t('advanced'),
                value: 'advanced',
                children: () => React.createElement(AdvancedSettings, Object.assign({}, settingsProps))
            }
        ].filter(Boolean);
        return tabs;
    }
    render() {
        const menu = this.buildMenu();
        const selected = menu.find(item => item.value === this.state.selected) || menu[0];
        const tabs = menu.map(item => (React.createElement("li", { key: item.value, id: `settings_tab_${item.value}`, className: cx(styles.tabItem, { [styles.selectedTab]: item.value === selected.value }) },
            React.createElement("button", { onClick: () => {
                    this.setState({ selected: item.value });
                } }, item.label))));
        const children = typeof selected.children === 'function' ? selected.children() : undefined;
        return (React.createElement("div", { className: cx(styles.container, {
                [styles.sessionSettings]: this.props.inSession,
                transparent: this.props.inSession
            }) },
            React.createElement("div", { className: styles.columns },
                React.createElement("aside", { className: styles.tabSidebar },
                    React.createElement("ul", null, tabs)),
                React.createElement("section", { className: styles.content },
                    React.createElement("h2", null, selected.label),
                    children))));
    }
}
export const SettingsMenu = connect((state) => {
    return {
        settings: state.settings
    };
}, dispatch => {
    const bindSetSetting = (key, value) => {
        return dispatch(setSetting(key, value));
    };
    return {
        setSetting: bindSetSetting
    };
})(_SettingsMenu);
