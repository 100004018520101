import { Deferred } from 'utils/async';
import NetConnection from './connection';
import { RECONNECT_TIMEOUT } from 'constants/network';
/** WebRTC peer connection. */
export class RTCPeerConn extends NetConnection {
    constructor(id, peer) {
        super(id, peer);
        this.signalDeferred = new Deferred();
        this.onSignal = (signal) => {
            this.signalDeferred.resolve(signal);
            this.emit('signal', signal);
        };
        this.onStateChange = (state) => {
            if (state === 'disconnected') {
                this.emit('disconnect');
                this.once('connect', () => this.emit('reconnect'));
            }
        };
        this.peer = peer;
        this.peer.reconnectTimer = RECONNECT_TIMEOUT;
        this.peer.on('close', this.close);
        this.peer.on('error', this.onError);
        this.peer.on('iceStateChange', this.onStateChange);
        this.peer.once('connect', this.onConnect);
        if (this.peer.connected) {
            this.onConnect();
        }
        else {
            this.peer.on('signal', this.onSignal);
        }
    }
    onClose() {
        super.onClose();
        this.peer.destroy();
        this.peer.removeAllListeners();
    }
    async getSignal() {
        return this.signalDeferred.promise;
    }
    signal(signal) {
        if (this.peer) {
            this.peer.signal(signal);
        }
    }
    receive(data) {
        // HACK: Workaround simple-peer bug where data is received before
        // 'connect' event
        if (!this.connected) {
            console.warn('Received RTCDataChannel data prior to connect event.');
            this.once('connect', () => this.receive(data));
            return;
        }
        super.receive(data);
    }
    getIP() {
        return this.peer.remoteAddress;
    }
    getPort() {
        return this.peer.remotePort;
    }
    /** Whether the DataChannel buffer can accept more data. */
    readyToReceive() {
        const dc = this.peer._channel;
        return dc ? dc.bufferedAmount < dc.bufferedAmountLowThreshold : false;
    }
    /** Wait for DataChannel buffer to fall below byte threshold. */
    waitUntilReady() {
        if (this.readyToReceive())
            return Promise.resolve();
        const dc = this.peer._channel;
        if (!dc)
            return Promise.reject();
        return new Promise(resolve => {
            const done = () => {
                this.off('close', done);
                dc.removeEventListener('bufferedamountlow', done);
                resolve();
            };
            this.on('close', done);
            dc.addEventListener('bufferedamountlow', done);
        });
    }
}
