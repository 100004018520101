import React, { Component } from 'react';
import { ExternalLink } from './common/link';
import styles from './ErrorBoundary.css';
import { DISCORD_INVITE_URL, GITHUB_REPO_URL } from 'constants/social';
import { HighlightButton } from './common/button';
import { copyToClipboard } from 'utils/clipboard';
import { VERSION } from 'constants/app';
export class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    get errorText() {
        const { error, errorInfo } = this.state;
        return `Version: ${VERSION}
URL: ${location.href}
User-Agent: ${navigator.userAgent}

Stack trace:
${error ? error.stack : ''}

Component stack:
${errorInfo ? errorInfo.componentStack : ''}
`;
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        try {
            gtag('event', 'exception', {
                description: error.message,
                fatal: true
            });
        }
        catch { }
    }
    render() {
        const { error } = this.state;
        if (error) {
            return (React.createElement("div", { className: styles.container },
                React.createElement("div", null,
                    React.createElement("p", null, "\uD83D\uDE31 An error occured in Metastream."),
                    React.createElement("p", null,
                        "Please consider reporting this on Metastream\u2019s",
                        ' ',
                        React.createElement(ExternalLink, { className: "link", href: `${GITHUB_REPO_URL}/issues` }, "GitHub issue tracker"),
                        ' ',
                        "and/or",
                        ' ',
                        React.createElement(ExternalLink, { className: "link", href: DISCORD_INVITE_URL }, "Discord community.")),
                    React.createElement("pre", null, this.errorText),
                    React.createElement("p", null,
                        React.createElement("br", null),
                        "To continue, you can try reloading the application. If the problem persists, refer to the",
                        ' ',
                        React.createElement(ExternalLink, { className: "link", href: `${GITHUB_REPO_URL}/wiki/FAQ` }, "Metastream FAQ"),
                        ' ',
                        "for tips on troubleshooting."),
                    React.createElement("p", null,
                        React.createElement(HighlightButton, { icon: "refresh-cw", size: "medium", highlight: true, onClick: () => location.reload(true) }, "Reload"),
                        "\u00A0",
                        React.createElement(HighlightButton, { icon: "clipboard", size: "medium", onClick: () => {
                                copyToClipboard(this.errorText);
                            } }, "Copy error")))));
        }
        return this.props.children;
    }
}
