import { DEFAULT_USERNAME, DEFAULT_COLOR } from 'constants/settings';
import { localUserId } from '../../network';
export const getUser = (state, userId) => state.users.map[userId];
export const getUserName = (state, userId) => {
    const user = getUser(state, userId);
    return user ? user.name : DEFAULT_USERNAME;
};
export const getUserColor = (state, userId) => {
    const user = getUser(state, userId);
    return user ? user.color : DEFAULT_COLOR;
};
export const getHostId = (state) => state.users.host;
export const getHost = (state) => getUser(state, getHostId(state));
export const isHost = (state, userId = localUserId()) => getHostId(state) === userId;
export const hasRole = (state, userId, role) => {
    const user = getUser(state, userId);
    return user ? (user.role & role) !== 0 : false;
};
export const isDJ = (state, userId = localUserId()) => hasRole(state, userId, 1 /* DJ */);
export const isAdmin = (state, userId = localUserId()) => hasRole(state, userId, 2 /* Admin */);
export const getNumUsers = (state) => Object.keys(state.users.map).length;
export const findUser = (state, filter) => {
    const userIds = Object.keys(state.users.map);
    for (let i = 0; i < userIds.length; i++) {
        const user = state.users.map[userIds[i]];
        if (filter(user, i)) {
            return user;
        }
    }
};
export const findUserByName = (state, name) => findUser(state, user => user.name === name);
export const forEachUser = (state, callback) => {
    const userIds = Object.keys(state.users.map);
    for (let i = 0; i < userIds.length; i++) {
        const user = state.users.map[userIds[i]];
        callback(user);
    }
};
/** Create new unique name with counter appended. */
const appendNameCount = (state, name) => {
    let newName;
    let i = 0;
    do {
        i++;
        newName = `${name} (${i})`;
    } while (!!findUserByName(state, newName));
    return newName;
};
export const getUniqueName = (state, name) => {
    const isNameTaken = !!findUserByName(state, name);
    if (isNameTaken) {
        name = appendNameCount(state, name);
    }
    return name;
};
