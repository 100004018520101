import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { throttle, debounce } from 'lodash-es';
import styles from './VideoPlayer.css';
import { updateMedia, updatePlaybackTimer, server_requestSeek, server_requestPlayPause, server_requestSetPlaybackRate } from 'lobby/actions/mediaPlayer';
import { clamp } from 'utils/math';
import { MEDIA_REFERRER, MEDIA_SESSION_USER_AGENT } from 'constants/http';
import { assetUrl } from 'utils/appUrl';
import { getPlaybackTime2 } from 'lobby/reducers/mediaPlayer.helpers';
import { isHost } from 'lobby/reducers/users.helpers';
import { isEqual } from 'lodash-es';
import { Webview } from 'components/Webview';
import { ExtensionInstall } from './overlays/ExtensionInstall';
import { Icon } from '../Icon';
import { addChat } from '../../lobby/actions/chat';
import { MediaSession } from './MediaSession';
import { getPlayerSettings } from '../../reducers/settings';
import { SafeBrowse } from 'services/safeBrowse';
import { SafeBrowsePrompt } from './overlays/SafeBrowsePrompt';
import { localUserId } from 'network';
import { setPopupPlayer } from 'actions/ui';
import { EMBED_BLOCKED_DOMAIN_LIST } from 'constants/embed';
import { IdleScreen } from './overlays/IdleScreen';
import { setVolume } from 'actions/settings';
import { getDisabledMediaSessionDomains } from 'constants/domains';
import { canExtensionBlockRequests } from 'utils/extension';
import { PopupWindow } from 'components/Popup';
const processMediaDuration = (duration, prevDuration) => {
    duration = duration && !isNaN(duration) ? duration : undefined;
    if (!duration)
        return;
    // Hulu and Crunchyroll display videos only a few seconds long prior to
    // showing a full video. To avoid the issue of prematuring ending videos,
    // we just set a minimum duration.
    if (typeof prevDuration !== 'number') {
        duration = Math.max(duration, MEDIA_DURATION_MIN) || MEDIA_DURATION_MIN;
    }
    return duration;
};
const DEFAULT_URL = assetUrl('idlescreen.html');
const MEDIA_TIMEOUT_DURATION = 10e3;
const MEDIA_DURATION_MIN = 10e3;
const mapStateToProps = (state) => {
    return {
        ...state.mediaPlayer,
        mute: state.settings.mute,
        volume: state.settings.volume,
        host: isHost(state),
        isExtensionInstalled: state.ui.isExtensionInstalled,
        playerSettings: getPlayerSettings(state),
        safeBrowseEnabled: state.settings.safeBrowse,
        popupPlayer: state.ui.popupPlayer
    };
};
class _VideoPlayer extends PureComponent {
    constructor() {
        super(...arguments);
        this.webview = null;
        /** Last time any activity occurred within the media frame. */
        this.lastActivityTime = 0;
        /** Last time the user interacted within the media frame. */
        this.lastInteractTime = 0;
        this.lastPlaybackPause = 0;
        this.state = { interacting: false, mediaReady: false, permitURLOnce: false };
        this.setupWebview = (webview) => {
            const prevWebview = this.webview;
            this.webview = webview;
            if (prevWebview) {
                prevWebview.removeEventListener('message', this.onIpcMessage);
                prevWebview.removeEventListener('ready', this.reload);
            }
            if (this.webview) {
                this.webview.addEventListener('message', this.onIpcMessage);
                this.webview.addEventListener('ready', this.reload);
            }
        };
        this.onIpcMessage = (action, ...args) => {
            if (typeof action !== 'object' || action === null)
                return;
            console.debug('VideoPlayer IPC', action);
            const isTopSubFrame = !!args[0];
            // Time since last activity in the frame. Used to ignore events that happen
            // not on the user's behalf.
            const activityTimeDelta = Date.now() - this.lastActivityTime;
            const interactTimeDelta = Date.now() - this.lastInteractTime;
            switch (action.type) {
                case 'media-ready':
                    this.onMediaReady(isTopSubFrame, action.payload);
                    break;
                case 'media-autoplay-error':
                    this.onAutoplayError(action.payload.error);
                    break;
                case 'media-playback-change':
                    // Need to react only to user interaction to prevent infinite loop
                    // of play/pause
                    if (interactTimeDelta <= 1000) {
                        this.onMediaPlaybackChange(action.payload);
                    }
                    break;
                case 'media-seeked':
                    if (interactTimeDelta <= 5000) {
                        this.onMediaSeek(action.payload);
                    }
                    break;
                case 'media-volume-change':
                    if (activityTimeDelta <= 1000) {
                        this.onMediaVolumeChange(action.payload.value);
                    }
                    else {
                        // overwrite changes to volume update outside of user interacting
                        this.updateVolume();
                    }
                    break;
                case 'media-playback-rate-change':
                    this.onMediaPlaybackRateChange(action.payload.value);
                    break;
            }
        };
        this.onMediaPlaybackChange = throttle((event) => {
            if (this.isPlaying && event.state === 'paused') {
                this.props.dispatch(server_requestPlayPause());
                this.lastPlaybackPause = Date.now();
            }
            else if (this.isPaused && event.state === 'playing') {
                this.props.dispatch(server_requestPlayPause());
            }
        }, 200, { leading: true, trailing: true });
        this.onMediaSeek = throttle((time) => {
            this.props.dispatch(server_requestSeek(time));
            // Resume playback if player paused prior to seek
            if (this.isPaused && Date.now() - this.lastPlaybackPause < 500) {
                this.props.dispatch(server_requestPlayPause());
            }
        }, 500, { leading: true, trailing: true });
        this.onMediaVolumeChange = debounce((volume) => {
            this.props.dispatch(setVolume(this.unscaleVolume(volume)));
        }, 200);
        this.onMediaPlaybackRateChange = throttle((playbackRate) => {
            // ignore invalid sent when video is loading
            if (playbackRate <= 0)
                return;
            this.props.dispatch(server_requestSetPlaybackRate(playbackRate));
        }, 200, { leading: true, trailing: true });
        this.onMediaReady = (isTopSubFrame = false, payload) => {
            console.debug('onMediaReady', payload);
            if (!this.state.mediaReady) {
                this.setState({ mediaReady: true });
            }
            if (this.mediaTimeout) {
                clearTimeout(this.mediaTimeout);
                this.mediaTimeout = -1;
            }
            this.updatePlayerSettings(this.props.playerSettings);
            // Apply auto-fullscreen to all subframes with nested iframes
            const isValidFrameSender = !isTopSubFrame || this.shouldRenderPopup;
            if (isValidFrameSender && payload) {
                this.dispatchMedia('apply-fullscreen', payload.href);
            }
            this.updateVolume();
            this.updatePlaybackTime();
            this.updatePlaybackRate(this.props.playbackRate);
            this.updatePlayback(this.props.playback);
            const media = this.props.current;
            if (this.props.host) {
                const prevDuration = media ? media.duration : undefined;
                const nextDuration = processMediaDuration(payload && payload.duration, prevDuration);
                const isLiveMedia = prevDuration === 0;
                const noDuration = !prevDuration;
                const isLongerDuration = nextDuration && (prevDuration && nextDuration > prevDuration);
                if (nextDuration && !isLiveMedia && (noDuration || isLongerDuration)) {
                    this.props.dispatch(updateMedia({ duration: nextDuration }));
                    this.props.dispatch(updatePlaybackTimer());
                }
            }
        };
        this.onMediaTimeout = () => {
            // Ignore idlescreen timeout
            if (this.props.playback === 0 /* Idle */)
                return;
            const hasInteracted = Boolean(localStorage.getItem("hasInteracted" /* HasInteracted */));
            if (hasInteracted)
                return;
            const content = '⚠️ Playback not detected. If media doesn’t autoplay, you may need to interact with the webpage by double-clicking the screen.';
            this.props.dispatch(addChat({ content, timestamp: Date.now() }));
        };
        this.onAutoplayError = (error) => {
            if (error !== 'NotAllowedError')
                return;
            const hasShownNotice = Boolean(sessionStorage.getItem("autoplayNotice" /* AutoplayNotice */));
            if (hasShownNotice)
                return;
            const content = '⚠️ Autoplay permissions are blocked. Enable autoplay in your browser for a smoother playback experience. Reload the video if it doesn’t start.';
            this.props.dispatch(addChat({ content, timestamp: Date.now() }));
            try {
                sessionStorage.setItem("autoplayNotice" /* AutoplayNotice */, '1');
            }
            catch { }
        };
        this.updatePlaybackTime = () => {
            const { current: media } = this.props;
            if (media && media.duration === 0) {
                console.debug('Preventing updating playback since duration indicates livestream');
                return; // live stream
            }
            let time = getPlaybackTime2(this.props);
            if (typeof time === 'number') {
                this.dispatchMedia('seek-media', time);
            }
        };
        this.updatePlayback = (state) => {
            this.dispatchMedia('set-media-playback', state);
        };
        this.updatePlaybackRate = (playbackRate) => {
            this.dispatchMedia('set-media-playback-rate', playbackRate);
        };
        this.updatePlayerSettings = (settings) => {
            let url;
            try {
                url = new URL(this.mediaUrl);
                // 2024: disable due to blackscreens during seek
                if (getDisabledMediaSessionDomains().has(url.hostname)) {
                    settings = { ...settings, mediaSessionProxy: false };
                }
            }
            catch {
                // ignore
            }
            this.dispatchMedia('set-settings', settings);
        };
        this.updateVolume = () => {
            const { volume, mute } = this.props;
            const newVolume = mute ? 0 : volume;
            this.dispatchMedia('set-media-volume', this.scaleVolume(newVolume));
        };
        this.onActivity = (eventName) => {
            this.lastActivityTime = Date.now();
            if (eventName !== 'mousemove') {
                this.lastInteractTime = Date.now();
            }
        };
        this.renderInteract = () => {
            if (!this.canEnterInteractMode)
                return;
            const msg = this.props.host
                ? '⚠️ Interact mode enabled. Only playback changes will be synced. ⚠️'
                : '⚠️ Interact mode enabled. Changes will only affect your local web browser. ⚠️';
            return this.state.interacting ? (React.createElement("button", { className: styles.interactNotice, onClick: this.exitInteractMode },
                msg,
                React.createElement(Icon, { name: "x", pointerEvents: "none", className: styles.btnExitInteract }))) : (React.createElement("div", { className: styles.interactTrigger, onDoubleClick: this.enterInteractMode }));
        };
        this.reload = () => {
            // Pause media to prevent continued playback in case next media takes time to load
            this.updatePlayback(2 /* Paused */);
            this.setState({ mediaReady: false });
            this.cancelCallbacks();
            this.maybeClearPopup();
            if (this.mediaTimeout)
                clearTimeout(this.mediaTimeout);
            this.mediaTimeout = setTimeout(this.onMediaTimeout, MEDIA_TIMEOUT_DURATION);
            if (this.webview) {
                this.webview.loadURL(this.mediaUrl, {
                    httpReferrer: this.httpReferrer,
                    userAgent: MEDIA_SESSION_USER_AGENT
                });
            }
        };
        this.enterInteractMode = () => {
            if (!this.canEnterInteractMode)
                return;
            this.setState({ interacting: true }, () => {
                document.addEventListener('keydown', this.onKeyDown, false);
                this.dispatchMedia('set-interact', true);
                if (this.props.onInteractChange) {
                    this.props.onInteractChange(this.state.interacting);
                }
            });
            try {
                localStorage.setItem("hasInteracted" /* HasInteracted */, '1');
            }
            catch { }
        };
        this.exitInteractMode = () => {
            document.removeEventListener('keydown', this.onKeyDown, false);
            this.dispatchMedia('set-interact', false);
            this.setState({ interacting: false }, () => {
                if (this.props.onInteractChange) {
                    this.props.onInteractChange(this.state.interacting);
                }
            });
        };
        this.onKeyDown = (event) => {
            switch (event.key) {
                case 'Escape':
                    this.exitInteractMode();
                    return;
            }
        };
    }
    get isPlaying() {
        return this.props.playback === 1 /* Playing */;
    }
    get isPaused() {
        return this.props.playback === 2 /* Paused */;
    }
    get mediaUrl() {
        const media = this.props.current;
        return media ? media.url : DEFAULT_URL;
    }
    // HACK: Set http referrer to itself to avoid referral blocking
    get httpReferrer() {
        const media = this.props.current;
        if (media && media.state && media.state.referrer) {
            return MEDIA_REFERRER;
        }
        const { mediaUrl } = this;
        try {
            const url = new URL(mediaUrl);
            return url.origin;
        }
        catch (e) {
            return mediaUrl;
        }
    }
    get isPermittedBySafeBrowse() {
        const media = this.props.current;
        // Always playback self-requested media
        if (media && media.ownerId === localUserId()) {
            return true;
        }
        return this.props.safeBrowseEnabled
            ? this.state.permitURLOnce || SafeBrowse.getInstance().isPermittedURL(this.mediaUrl)
            : true;
    }
    get canEnterInteractMode() {
        if (!this.props.isExtensionInstalled)
            return false;
        if (!this.isPermittedBySafeBrowse)
            return false;
        if (this.shouldRenderPopup)
            return false;
        if (this.props.playback === 0 /* Idle */)
            return false;
        return true;
    }
    get canEmbed() {
        let url;
        try {
            url = new URL(this.mediaUrl);
        }
        catch {
            return true;
        }
        const isEmbedBlocked = EMBED_BLOCKED_DOMAIN_LIST.has(url.host);
        // can't embed http inside of https
        const isMixedContent = url.protocol === 'http:';
        return !(isEmbedBlocked || isMixedContent);
    }
    get shouldRenderPopup() {
        return this.props.popupPlayer || !this.canEmbed;
    }
    componentDidMount() {
        if (this.props.theRef) {
            this.props.theRef(this);
        }
    }
    componentWillUnmount() {
        if (this.props.theRef) {
            this.props.theRef(null);
        }
        if (this.mediaTimeout) {
            clearTimeout(this.mediaTimeout);
        }
        this.props.dispatch(updatePlaybackTimer());
        this.cancelCallbacks();
    }
    cancelCallbacks() {
        this.onMediaPlaybackChange.cancel();
        this.onMediaSeek.cancel();
        this.onMediaVolumeChange.cancel();
        this.onMediaPlaybackRateChange.cancel();
    }
    maybeClearPopup() {
        if (this.props.popupPlayer && !PopupWindow.isOpen()) {
            this.props.dispatch(setPopupPlayer(false));
        }
    }
    componentDidUpdate(prevProps) {
        const { current, playerSettings } = this.props;
        const { current: prevMedia } = prevProps;
        const didInstallExtension = this.props.isExtensionInstalled !== prevProps.isExtensionInstalled;
        if (didInstallExtension) {
            this.reload();
            return;
        }
        if (playerSettings !== prevProps.playerSettings) {
            this.updatePlayerSettings(playerSettings);
        }
        if (current !== prevMedia) {
            if (isEqual(current, prevMedia)) {
                // Ignore: new object, same properties
            }
            else if (current && prevMedia && current.url === prevMedia.url && this.state.mediaReady) {
                // Force restart media if new media is the same URL
                this.onMediaReady();
                return;
            }
            else {
                // Update URL on webview otherwise
                if (this.state.permitURLOnce)
                    this.setState({ permitURLOnce: false });
                this.reload();
                return;
            }
        }
        const didVolumeUpdate = this.props.volume !== prevProps.volume || this.props.mute !== prevProps.mute;
        const didPlaybackUpdate = this.props.playback !== prevProps.playback;
        const didPause = didPlaybackUpdate && this.props.playback === 2 /* Paused */;
        const didPlaybackTimeUpdate = (this.isPlaying && this.props.startTime !== prevProps.startTime) ||
            (this.isPaused && this.props.pauseTime !== prevProps.pauseTime);
        const didPlaybackRateUpdate = this.props.playbackRate !== prevProps.playbackRate;
        if (didVolumeUpdate)
            this.updateVolume();
        // Update playback time if we didn't pause
        // Pause+seek causes issues for some video players where they trigger
        // starting playback after seeking
        if (didPlaybackTimeUpdate && !didPause)
            this.updatePlaybackTime();
        if (didPlaybackUpdate)
            this.updatePlayback(this.props.playback);
        if (didPlaybackRateUpdate)
            this.updatePlaybackRate(this.props.playbackRate);
    }
    dispatchMedia(type, payload) {
        if (this.webview) {
            this.webview.dispatchRemoteEvent('metastream-host-event', { type, payload }, { allFrames: true });
        }
    }
    /**
     * Use dB scale to convert linear volume to exponential.
     * https://www.dr-lex.be/info-stuff/volumecontrols.html
     */
    scaleVolume(volume) {
        return volume === 0 ? 0 : clamp(Math.exp(6.908 * volume) / 1000, 0, 1);
    }
    /** Convert exponential volume into linear. */
    unscaleVolume(volume) {
        return volume === 0 ? 0 : clamp(Math.log(volume * 1000) / 6.908, 0, 1);
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, this.props.className), onDoubleClick: this.enterInteractMode },
            this.renderMediaSession(),
            this.renderInteract(),
            this.renderBrowser(),
            this.props.playback === 0 /* Idle */ && this.renderIdleScreen()));
    }
    renderMediaSession() {
        if (!('mediaSession' in navigator))
            return;
        return (React.createElement(MediaSession, { playing: this.props.playback === 1 /* Playing */, muted: this.props.mute || this.props.volume === 0 }));
    }
    renderBrowser() {
        const { mediaUrl } = this;
        const { current: media } = this.props;
        if (!this.props.isExtensionInstalled) {
            return React.createElement(ExtensionInstall, null);
        }
        if (!this.isPermittedBySafeBrowse) {
            return (React.createElement(SafeBrowsePrompt, { url: mediaUrl, onChange: () => this.forceUpdate(), onPermitOnce: () => {
                    this.setState({ permitURLOnce: true });
                } }));
        }
        return (React.createElement(Webview, { componentRef: this.setupWebview, src: DEFAULT_URL, mediaSrc: this.mediaUrl, className: cx(styles.video, {
                [styles.interactive]: this.state.interacting,
                [styles.playing]: !!this.props.current,
                [styles.mediaReady]: this.state.mediaReady
            }), allowScripts: true, popup: this.shouldRenderPopup, onClosePopup: () => {
                if (this.props.popupPlayer) {
                    this.props.dispatch(setPopupPlayer(false));
                }
            }, backgroundImage: (media && media.imageUrl) || undefined, onActivity: this.onActivity, onBlocked: () => {
                if (canExtensionBlockRequests())
                    return;
                // if (!this.props.popupPlayer) {
                //   this.props.dispatch(setPopupPlayer(true))
                // }
            } }));
    }
    renderIdleScreen() {
        if (!this.props.isExtensionInstalled || this.shouldRenderPopup)
            return;
        return React.createElement(IdleScreen, null);
    }
}
export const VideoPlayer = connect(mapStateToProps)(_VideoPlayer);
