import React, { useState, useRef } from 'react';
import cx from 'classnames';
import styles from './HomeScreen.css';
export const HomeScreen = (props) => {
    const [showTips, setShowTips] = useState(!localStorage.getItem("tipsDismissed" /* TipsDismissed */));
    const urlInputRef = useRef(null);
    const externalHref = (href) => `./external.html?href=${encodeURIComponent(href)}`;
    return (React.createElement("div", { className: styles.container },
        React.createElement("header", { id: "tips", className: cx(styles.tips, {
                [styles.tipsExpanded]: showTips
            }) },
            React.createElement("button", { id: "tipsbtn", className: styles.tipsButton, onClick: () => {
                    setShowTips(!showTips);
                    localStorage.setItem("tipsDismissed" /* TipsDismissed */, '1');
                } },
                React.createElement("div", { className: styles.column },
                    React.createElement("b", null, "\uD83D\uDCDD Tips for getting started"),
                    React.createElement("svg", { width: "24", viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" },
                        React.createElement("polyline", { points: "4 8 12 16 20 8" })))),
            React.createElement("div", { className: "tips-content" },
                React.createElement("p", { className: styles.column }, "From here you can browse the web to find media to share in the session."),
                React.createElement("ul", { className: cx(styles.column, styles.emojiList) },
                    React.createElement("li", null,
                        React.createElement("span", { className: styles.emojiBullet }, "\uD83C\uDF10"),
                        " Metastream supports many websites! Don't see one in the list? Try adding the link anyway!"),
                    React.createElement("li", null,
                        React.createElement("span", { className: styles.emojiBullet }, "\uD83D\uDC65"),
                        " Everyone in the session will need an account or subscription if required by a website."),
                    React.createElement("li", null,
                        React.createElement("span", { className: styles.emojiBullet }, "\uD83D\uDC1B"),
                        " Is a website not working how you'd expect? Help us out by",
                        ' ',
                        React.createElement("a", { className: "link", href: "https://github.com/samuelmaddock/metastream/issues", target: "_blank" }, "reporting the issue on GitHub."))))),
        React.createElement("main", { className: styles.main },
            React.createElement("section", { style: { maxWidth: '100%' } },
                React.createElement("div", { className: cx(styles.column, styles.gridContainer) },
                    React.createElement("a", { href: "https://www.youtube.com/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "YouTube"),
                    React.createElement("a", { href: "https://www.twitch.tv/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Twitch"),
                    React.createElement("a", { href: "https://www.netflix.com", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Netflix"),
                    React.createElement("a", { href: "https://www.hulu.com/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Hulu"),
                    React.createElement("a", { href: "https://www.disneyplus.com/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Disney+"),
                    React.createElement("a", { href: "https://www.crunchyroll.com/videos/anime", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Crunchyroll"),
                    React.createElement("a", { href: "https://soundcloud.com/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "SoundCloud"),
                    React.createElement("a", { href: "https://www.reddit.com/", className: cx(styles.button, styles.socialLink), rel: "noopener" }, "Reddit")),
                React.createElement("div", { className: cx(styles.column, styles.inputContainer) },
                    React.createElement("input", { ref: urlInputRef, id: "urlinput", placeholder: "Or paste any link (e.g. https://cool.website/video/123)", autoComplete: "url", spellCheck: false }),
                    React.createElement("button", { id: "addbtn", className: cx(styles.button, styles.uppercase), onClick: () => {
                            if (urlInputRef.current) {
                                props.onRequestUrl(urlInputRef.current.value);
                            }
                        } }, "Add to Session"))))));
};
