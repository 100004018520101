import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './IdleScreen.css';
import { HighlightButton } from 'components/common/button';
import { t } from 'locale';
import { setLobbyModal } from 'actions/ui';
import { hasPlaybackPermissions } from 'lobby/reducers/mediaPlayer.helpers';
import { AdInsert } from 'adsense';
const _IdleScreen = (props) => {
    // Hide CTA while browser is open
    if (props.isModalOpen)
        return null;
    const isAddAllowed = props.dj || !props.queueLocked;
    const addMediaCTA = (React.createElement(React.Fragment, null,
        React.createElement("p", null, t('addMediaCTA')),
        React.createElement(HighlightButton, { icon: "plus", size: "large", highlight: true, onClick: props.openMediaBrowser }, t('addMedia'))));
    return (React.createElement("div", { className: cx(styles.container, props.className) },
        FEATURE_ADS ? (React.createElement("div", { className: styles.row },
            React.createElement(AdInsert, { slot: "4439397326", format: "auto", "data-full-width-responsive": "true" }))) : null,
        isAddAllowed && addMediaCTA));
};
export const IdleScreen = connect(state => ({
    isModalOpen: Boolean(state.ui.lobbyModal),
    dj: hasPlaybackPermissions(state),
    queueLocked: state.mediaPlayer.queueLocked
}), (dispatch) => ({
    openMediaBrowser() {
        dispatch(setLobbyModal("browser" /* Browser */));
    }
}))(_IdleScreen);
