import { opfs } from './opfs-fs';
import { dirname } from 'path';
import { getMimeType } from 'utils/file';
let fileSystem;
/**
 * File System
 * Currently used for caching networked files.
 */
export class FileSystem {
    constructor() {
        this.cachedObjectURLs = new Map();
        this.dirLimits = new Map();
    }
    static getInstance() {
        if (!fileSystem) {
            fileSystem = new FileSystem();
        }
        return fileSystem;
    }
    async access(path, mode) {
        await opfs.access(path, mode);
    }
    mkdir(path) {
        return opfs.mkdir(path);
    }
    async readFile(path) {
        return await opfs.readFile(path);
    }
    readdir(dir) {
        return opfs.readdir(dir);
    }
    stat(path) {
        return opfs.stat(path);
    }
    async writeFile(path, data) {
        await opfs.writeFile(path, data);
        void this.onWriteFile(path);
    }
    symlink(srcpath, destpath) {
        return opfs.symlink(srcpath, destpath);
    }
    unlink(path) {
        return opfs.unlink(path);
    }
    utimes(path, atime, mtime) {
        return opfs.utimes(path, atime, mtime);
    }
    setDirFileLimit(dirpath, limit) {
        this.dirLimits.set(dirpath, limit);
    }
    // Evict files after limit has been reached
    // TODO: implement this with a file watcher instead
    async onWriteFile(path) {
        const dir = dirname(path);
        if (!this.dirLimits.has(dir))
            return;
        const limit = this.dirLimits.get(dir);
        const files = await this.readdir(dir);
        let count = files.length;
        if (count <= limit)
            return;
        const stats = [];
        for (const filename of files) {
            const stat = await this.stat(`${dir}/${filename}`);
            stats.push({ filename, atime: stat.atime });
        }
        // Sort by ASC atime (oldest accessed first)
        stats.sort((a, b) => a.atime - b.atime);
        for (let i = 0; i < count - limit; i++) {
            await this.unlink(`${dir}/${stats[i].filename}`);
        }
    }
    async readFileURL(pathname) {
        let objectUrl = this.cachedObjectURLs.get(pathname);
        if (objectUrl)
            return objectUrl;
        const data = await this.readFile(pathname);
        const mimeType = getMimeType(pathname);
        const blob = new Blob([data], { type: mimeType });
        objectUrl = URL.createObjectURL(blob);
        this.cachedObjectURLs.set(pathname, objectUrl);
        return objectUrl;
    }
    cleanCache() {
        for (const [, url] of this.cachedObjectURLs) {
            URL.revokeObjectURL(url);
        }
        this.cachedObjectURLs.clear();
    }
}
