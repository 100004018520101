import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import cx from 'classnames';
import { PRODUCT_NAME } from 'constants/app';
import { isUpdateAvailable } from 'reducers/ui';
import styles from './TitleBar.css';
import { IconButton } from 'components/common/button';
import { Icon } from './Icon';
import { UpdateService } from 'services/updater';
import { openInBrowser } from 'utils/url';
import { t } from 'locale';
const wco = navigator.windowControlsOverlay;
let _TitleBar = /** @class */ (() => {
    class _TitleBar extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                wcoVisible: wco && wco.visible || false
            };
            this.onWcoChange = () => {
                const wcoVisible = wco.visible;
                if (wco.visible !== this.state.wcoVisible) {
                    this.setState({ wcoVisible });
                }
            };
        }
        componentDidMount() {
            if (wco) {
                wco.addEventListener('geometrychange', this.onWcoChange);
            }
        }
        render() {
            const updateButton = this.props.updateAvailable && (React.createElement(IconButton, { icon: "download", className: styles.updateButton, onClick: () => UpdateService.getInstance().update() }, "Update"));
            const style = this.state.wcoVisible ? {
                height: wco.getTitlebarAreaRect().height
            } : undefined;
            return (React.createElement("div", { className: cx(this.props.className, styles.container, {
                    [styles.fullwidth]: this.props.fullWidth
                }), style: style },
                React.createElement("div", { className: styles.wrapper },
                    React.createElement("header", { className: styles.header },
                        React.createElement("h2", { className: styles.title }, this.props.title || PRODUCT_NAME),
                        React.createElement("div", { className: styles.drag })),
                    updateButton,
                    this.props.showBackButton && this.renderBack(),
                    this.renderFrameButtons())));
        }
        renderFrameButtons() {
            const isFullscreen = !!document.fullscreenElement;
            return (React.createElement("div", { className: styles.rightActions },
                React.createElement("button", { type: "button", className: styles.actionButton, onClick: () => {
                        openInBrowser('https://github.com/samuelmaddock/metastream/wiki/FAQ');
                    }, title: t('help') },
                    React.createElement(Icon, { name: "help-circle" })),
                React.createElement("button", { type: "button", className: styles.actionButton, onClick: () => {
                        if (isFullscreen) {
                            try {
                                document.exitFullscreen();
                            }
                            catch { }
                        }
                        else {
                            document.documentElement.requestFullscreen();
                        }
                        setTimeout(() => this.forceUpdate(), 100);
                    } },
                    React.createElement(Icon, { name: isFullscreen ? 'minimize-2' : 'maximize-2' }))));
        }
        renderBack() {
            const onBack = this.props.onBack;
            const defaultOnBack = () => this.props.dispatch(push('/'));
            return (React.createElement("div", { className: styles.leftActions },
                React.createElement("button", { type: "button", className: styles.actionButton, onClick: onBack ? () => onBack(defaultOnBack) : defaultOnBack },
                    React.createElement(Icon, { name: "arrow-left" }))));
        }
    }
    _TitleBar.defaultProps = {
        showBackButton: true,
        fullWidth: true
    };
    return _TitleBar;
})();
export const TitleBar = connect((state) => {
    return {
        updateAvailable: isUpdateAvailable(state)
    };
})(_TitleBar);
