import React, { Component } from 'react';
import cx from 'classnames';
import { ExternalLink } from '../common/link';
import { HOME_WEBSITE } from 'constants/http';
import styles from './MenuTabs.css';
import { t } from 'locale';
import { Fetch } from '../common/Fetch';
export class MenuTabs extends Component {
    constructor() {
        super(...arguments);
        this.state = { value: 0 };
    }
    render() {
        const tabs = [
            { label: t('welcome'), render: () => React.createElement(WelcomeMessage, null) },
        ];
        const selected = tabs[this.state.value];
        return (React.createElement("div", { className: styles.container },
            React.createElement("ul", { className: styles.tabList }, tabs.map((t, idx) => (React.createElement("li", { key: idx, className: cx(styles.tabItem, {
                    [styles.selected]: selected === t
                }) },
                React.createElement("button", { className: styles.tabButton, onClick: () => this.setState({ value: idx }) },
                    React.createElement("span", { className: styles.tabLabel }, t.label)))))),
            React.createElement("div", { className: styles.content }, selected && selected.render())));
    }
}
const WelcomeMessage = () => (React.createElement(React.Fragment, null,
    React.createElement("p", null, t('welcomeMessage1')),
    React.createElement("p", null,
        "If you come across any issues, please consider",
        ' ',
        React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/issues" }, "submitting a GitHub issue.")),
    React.createElement("p", null, "We also have a Discord community linked at the bottom of the screen where folks can chat and share your sessions."),
    React.createElement("p", null,
        "To learn more about Metastream,",
        ' ',
        React.createElement(ExternalLink, { href: "https://github.com/samuelmaddock/metastream/wiki/FAQ" }, "check out the FAQ.")),
    React.createElement("p", null,
        React.createElement("em", null, "- Sam"))));
const Donators = () => (React.createElement(Fetch, { cacheKey: "donators", href: `${HOME_WEBSITE}/app/donators.txt` }, data => React.createElement("p", { style: { whiteSpace: 'pre-wrap' } }, typeof data === 'string' ? data : '')));
