import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './WebBrowser.css';
import { WebControls } from 'components/browser/Controls';
import { assetUrl, absoluteUrl } from 'utils/appUrl';
import { Webview } from 'components/Webview';
import { sendMediaRequest } from 'lobby/actions/media-request';
import { HomeScreen } from './HomeScreen';
import { Portal } from 'components/Portal';
const DEFAULT_URL = absoluteUrl(`${assetUrl('webview.html')}`);
export class _WebBrowser extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
        this.dispatchCommand = (cmd) => {
            if (!this.webview) {
                return;
            }
            switch (cmd) {
                case 'window:close':
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                    break;
                case 'window:focus-url':
                    if (this.controls) {
                        this.controls.focusURL();
                    }
                    break;
                case 'window:history-prev':
                    // if (this.webContents.canGoBack()) {
                    //   this.webContents.goBack()
                    // }
                    break;
                case 'window:history-next':
                    // if (this.webContents.canGoForward()) {
                    //   this.webContents.goForward()
                    // }
                    break;
            }
        };
        this.setupWebview = (webview) => {
            this.webview = webview;
            if (this.webview) {
                this.setupControls();
            }
            else if (this.hasSetupControls && this.controls) {
                this.controls.setWebview(null);
            }
        };
        this.requestUrl = (url, source) => {
            this.props.dispatch(sendMediaRequest({ url, source }));
            if (this.props.onClose) {
                this.props.onClose();
            }
        };
    }
    get initialUrl() {
        return this.props.initialUrl || DEFAULT_URL;
    }
    setupControls() {
        if (!this.hasSetupControls && this.controls && this.webview) {
            this.controls.setWebview(this.webview);
            this.hasSetupControls = true;
        }
    }
    render() {
        return (React.createElement("div", { className: cx(styles.container, this.props.className) },
            React.createElement(WebControls, { ref: el => {
                    this.controls = el;
                    this.setupControls();
                }, initialUrl: this.initialUrl, onClose: this.props.onClose, onRequestUrl: url => this.requestUrl(url, 'browser') }),
            this.renderContent(),
            this.state.showHomescreen && this.renderHomescreen()));
    }
    renderContent() {
        return (React.createElement(Webview, { componentRef: this.setupWebview, src: this.initialUrl, className: styles.content, onNavigate: url => {
                const showHomescreen = url === DEFAULT_URL;
                if (showHomescreen) {
                    this.setState({ showHomescreen: false }); // force remount
                    this.setState({ showHomescreen });
                }
            }, onBlocked: (url) => {
                // if (!this.webview) return
                // const redirectUrl = `./assets/external.html?href=${encodeURIComponent(url)}`
                // this.webview.loadURL(redirectUrl)
            } }));
    }
    renderHomescreen() {
        const iframe = this.webview && this.webview.getIFrame();
        const wvDoc = iframe && iframe.contentDocument && iframe.contentDocument.body;
        if (!wvDoc)
            return null;
        return (React.createElement(Portal, { container: wvDoc },
            React.createElement(HomeScreen, { onRequestUrl: url => this.requestUrl(url, 'homescreen') })));
    }
}
export const WebBrowser = connect()(_WebBrowser);
