/**
 * Network version the client is currently using. This must match when
 * connecting to other clients. Will result in a VersionMismatch error
 * otherwise.
 *
 * This should be incremented each time a developer updates the design
 * of networked data structures.
 */
export const METASTREAM_NETWORK_VERSION = 7;
export const METASTREAM_SIGNAL_SERVER = process.env.METASTREAM_SIGNAL_SERVER || 'wss://signal.rtc.getmetastream.com';
const METASTREAM_STUN_SERVERS = [
    { url: 'stun:stun1.l.google.com:19302' },
    { url: 'stun:stun2.l.google.com:19302' }
];
const METASTREAM_TURN_SERVER = process.env.METASTREAM_TURN_CREDENTIAL && {
    url: process.env.METASTREAM_TURN_SERVER || 'turn:turn.rtc.getmetastream.com:5349?transport=tcp',
    username: process.env.METASTREAM_TURN_USERNAME || 'metastream',
    credential: process.env.METASTREAM_TURN_CREDENTIAL
};
// prettier-ignore
export const METASTREAM_ICE_SERVERS = [
    ...METASTREAM_STUN_SERVERS,
    METASTREAM_TURN_SERVER
].filter(Boolean);
export const NETWORK_TIMEOUT = 30e3;
export const RECONNECT_TIMEOUT = 30e3;
export const WEBSOCKET_PORT_DEFAULT = 27064;
export const NetworkDisconnectMessages = {
    [1 /* HostDisconnect */]: 'networkDisconnectHostDisconnect',
    [2 /* Error */]: 'networkDisconnectError',
    [3 /* InvalidClientInfo */]: 'networkDisconnectInvalidClientInfo',
    [4 /* VersionMismatch */]: `networkDisconnectVersionMismatch`,
    [5 /* Full */]: 'networkDisconnectFull',
    [6 /* Kicked */]: 'networkDisconnectKicked',
    [7 /* MultiTab */]: 'networkDisconnectMultiTab',
    [8 /* SessionNotFound */]: 'networkDisconnectSessionNotFound'
};
export const NetworkDisconnectLabels = {
    [1 /* HostDisconnect */]: 'host-disconnect',
    [2 /* Error */]: 'timeout',
    [3 /* InvalidClientInfo */]: 'invalid-client-info',
    [4 /* VersionMismatch */]: `version-mismatch`,
    [5 /* Full */]: 'full',
    [6 /* Kicked */]: 'kicked',
    [7 /* MultiTab */]: 'multi-tab',
    [8 /* SessionNotFound */]: 'session-not-found'
};
