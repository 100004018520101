import { assetUrl } from 'utils/appUrl';
import { EventEmitter } from 'events';
import { debounce } from 'lodash-es';
let avatarRegistry;
/** Avatar registry with support for multiple sources and URL resolution. */
export class AvatarRegistry extends EventEmitter {
    constructor() {
        super();
        this.types = new Map();
        this.avatars = [];
        /**
         * Announce change to avatars.
         * Delayed by one frame to prevent multiple updates per frame.
         */
        this.onChange = debounce(() => {
            this.emit('change');
        }, 0);
    }
    static getInstance() {
        if (!avatarRegistry) {
            avatarRegistry = new AvatarRegistry();
            initAppAvatars(avatarRegistry);
        }
        return avatarRegistry;
    }
    get length() {
        return this.avatars.length;
    }
    /** Register avatar type. */
    registerType(name, resolver, order = 0) {
        this.types.set(name, { name, resolver, order });
    }
    /** Register avatar. */
    async register(avatar) {
        const avatarType = this.types.get(avatar.type);
        if (!avatarType) {
            throw new Error(`Attempt to register avatar with unknown type '${avatar.type}'`);
        }
        const src = await avatarType.resolver(...avatar.params);
        if (!src) {
            throw new Error(`Attempt to register avatar with invalid params '${avatar.params.join(',')}'`);
        }
        const uri = `${avatar.type}:${avatar.params.join(',')}`;
        const entry = { ...avatar, uri, src };
        this.avatars = [...this.avatars, entry];
        this.sort();
        this.onChange();
        return entry;
    }
    /** Resolve avatar URI. */
    async resolve(uri) {
        const { type, params } = this.parseURI(uri);
        const avatarType = this.types.get(type);
        if (!avatarType) {
            throw new Error(`Attempt to resolve avatar with unknown type '${type}'`);
        }
        const result = await avatarType.resolver(...params);
        if (!result) {
            console.debug(`Failed to resolve ${uri}`);
        }
        return result;
    }
    parseURI(uri) {
        const [type, _params] = uri.split(':');
        const params = _params.split(',');
        return { type, params };
    }
    /** Whether URI has valid formatting */
    isValid(uri) {
        if (!uri)
            return false;
        const { type, params } = this.parseURI(uri);
        const avatarType = this.types.get(type);
        const resolver = avatarType && avatarType.resolver;
        return Boolean(resolver && resolver.length <= params.length);
    }
    getAll() {
        return this.avatars;
    }
    getByURI(uri) {
        return this.avatars.find(avatar => avatar.uri === uri);
    }
    deleteByURI(uri) {
        const index = this.avatars.findIndex(avatar => avatar.uri === uri);
        if (index) {
            const newAvatars = [...this.avatars];
            newAvatars.splice(index, 1);
            this.avatars = newAvatars;
            this.onChange();
            return true;
        }
        return false;
    }
    sort() {
        this.avatars.sort((a, b) => {
            const typeA = this.types.get(a.type);
            const typeB = this.types.get(b.type);
            if (typeA.order > typeB.order)
                return 1;
            if (typeA.order < typeB.order)
                return -1;
            return 0;
        });
    }
}
const pastelColors = [
    ['beebe9', 'f4dada', 'ffb6b9', 'f6eec7'],
    ['721b65', 'b80d57', 'f8615a', 'ffd868'],
    ['b590ca', 'a8d3da', 'f5cab3', 'f3ecb8'],
    ['2a1a5e', 'f45905', 'fb9224', 'fbe555'] // https://colorhunt.co/palette/158955
];
/** Procedurally generate an SVG gradient given a hex string. */
function generateGradientSvg(hex) {
    const buf = Buffer.from(hex.substr(0, 8), 'hex');
    const palette = pastelColors[buf.readUInt8(0) % pastelColors.length];
    const paletteLen = palette.length;
    const startIndex = buf.readUInt8(1) % paletteLen;
    const startColor = palette[startIndex];
    let endIndex = buf.readUInt8(2) % paletteLen;
    endIndex =
        startIndex === endIndex ? (endIndex + Math.floor(paletteLen / 2)) % paletteLen : endIndex;
    const endColor = palette[endIndex];
    const rotateDeg = buf.readUInt8(3) % 360;
    return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'>
<linearGradient id='g' gradientTransform='rotate(${rotateDeg} 0.5 0.5)'>
  <stop offset='0%' stop-color='%23${startColor}'></stop>
  <stop offset='100%' stop-color='%23${endColor}'></stop>
</linearGradient>
<rect width='100%' height='100%' fill='url(%23g)' />
</svg>`;
}
function initAppAvatars(avatarRegistry) {
    avatarRegistry.registerType("uid" /* UserID */, (hash) => {
        if (typeof hash !== 'string')
            return;
        const svg = generateGradientSvg(hash);
        const uri = 'data:image/svg+xml;utf8,' + svg;
        return uri;
    }, -1);
    avatarRegistry.registerType("asset" /* Asset */, (fileName) => {
        if (fileName && fileName.indexOf('..') > -1)
            return;
        return assetUrl(`avatars/${fileName}`);
    });
    const artistAvatars = [
        {
            name: '@Alisa_Aydin',
            href: 'https://twitter.com/Alisa_Aydin',
            fileNames: [
                'alisa-aydin_luna.png',
                'alisa-aydin_sailor-moon.png',
                'alisa-aydin_luna-zoom.png'
            ]
        }
    ];
    artistAvatars.forEach(artist => {
        artist.fileNames.forEach(fileName => {
            avatarRegistry.register({
                type: "asset" /* Asset */,
                artist: artist.name,
                href: artist.href,
                params: [fileName]
            });
        });
    });
}
